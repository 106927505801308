import React from 'react'
import { Upload, Layout, Breadcrumb, Button, Spin, DatePicker, message } from 'antd';
import { get } from 'common/utils.js';
import moment from 'moment';
import style from './index.module.scss';
import { UploadOutlined } from '@ant-design/icons'

export default class DailyReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            spinLoading: false,
            day: moment().subtract(1, 'days'),
            reportStatus: {},
            lightElecValue: null,
            lightElecSelected: 'OTHER',
            lightElecSaved: false,
            type2Info: null,
            fileConfigList: [
                {
                    name: "出库表格",
                    excel_file: null,
                    uploading: false,
                    type: 1,
                },
                {
                    name: "工单表格",
                    excel_file: null,
                    uploading: false,
                    type: 2,
                },
                {
                    name: "能源表格",
                    excel_file: null,
                    uploading: false,
                    type: 3,
                },
                {
                    name: "温度表格",
                    excel_file: null,
                    uploading: false,
                    type: 4,
                },
            ]
        }
    }

    componentWillMount() {
        this.fetchUploadRecord()
    }

    fetchUploadRecord = () => {
        this.setState({ spinLoading: true })
        const { day } = this.state
        get(process.env.REACT_APP_SERVER_PATH + 'api/xinchen/import/queryUploadRecord', {
            uploadDate: moment(day).format('YYYY-MM-DD')
        })
            .then(retData => {
                this.setState({ spinLoading: false })
                // console.log(retData)
                if (retData?.data?.error === 0) {
                    const n_type2Info = (retData?.data?.results ?? [])
                        .reduce((pV, cV) => {
                            return Object.assign(pV, { [`${cV.type}`]: cV })
                        }, {})
                    this.setState({ type2Info: n_type2Info })
                    // console.log(n_type2Info)
                } else {
                    message.warning('获取最新上传记录失败, 请刷新重试')
                }
            })
    }

    handleUpload = (item) => {
        let self = this;

        this.setState({ spinLoading: true });

        let formData = new FormData();
        formData.append('uploadDate', this.state.day.format('YYYY-MM-DD'));
        formData.append('file', item.excel_file);
        formData.append('type', `${item.type}`);

        var request = new XMLHttpRequest();
        // request.open("POST", 'http://localhost:7683/api/xinchen/import/upload');
        request.open("POST", process.env.REACT_APP_SERVER_PATH + 'api/xinchen/import/upload');
        // request.setRequestHeader(
        //     'Content-Type', 'multipart/form-data; boundary=<calculated when request is sent>'
        // )
        request.onload = function () {
            self.setState({ spinLoading: false });
            if (request.status === 200) { // 上传成功
                let result = JSON.parse(request.response);
                // console.log(result)
                if (result?.error === 0) {
                    // self.getReportListStatus();
                    alert("上传成功");
                    item.excel_file = null;
                    self.fetchUploadRecord()
                } else {
                    alert("上传失败，" + result?.results ?? '上传失败');
                }
            } else { // 处理其他情况
                alert("上传失败");
            }
            // item.excel_file = null;
        };
        request.onerror = function () {
            self.setState({ spinLoading: false });
            alert("上传失败");
            // item.excel_file = null;
        };
        request.send(formData);
    }

    dateOnChange = v => {
        this.setState({ day: moment(v) }, this.fetchUploadRecord);
    }

    render() {
        const { day, type2Info, fileConfigList, spinLoading } = this.state
        return (
            <Layout className={style["wrapper"]}>
                <Breadcrumb className="breadcrumb">
                    <Breadcrumb.Item></Breadcrumb.Item>
                    <Breadcrumb.Item></Breadcrumb.Item>
                </Breadcrumb>
                <Layout className="layout-first">
                    <Layout className="layout-second">
                        <Spin spinning={spinLoading}>
                            <div className="roleBasicInfo">
                                <span className="itemTitle">日&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;期：</span>
                                <DatePicker value={day} onChange={this.dateOnChange} />
                            </div>
                            {
                                fileConfigList.map((item, index) => {
                                    const { type } = item;
                                    return (
                                        <div className="fileLine" key={index}>
                                            <div className="itemTitle" style={{ lineHeight: '30px' }}>{item.name}：</div>
                                            <Upload
                                                fileList={item.excel_file ? [item.excel_file] : []}
                                                beforeUpload={file => {
                                                    item.excel_file = file;
                                                    this.forceUpdate();
                                                    return false;
                                                }}
                                                onRemove={file => {
                                                    item.excel_file = null;
                                                    this.forceUpdate();
                                                }}
                                            >
                                                <Button>
                                                    <UploadOutlined /> 选择文件
                                                </Button>
                                            </Upload>
                                            <Button
                                                type="primary"
                                                onClick={() => { this.handleUpload(item) }}
                                                disabled={item.excel_file === null}
                                                loading={item.uploading}
                                                style={{ marginLeft: 10 }}
                                            >
                                                {item.uploading ? '正在上传' : '上传'}
                                            </Button>
                                            <div className="statusInfo">{!!(type2Info?.[`${type}`] ?? null) ? ('已于' + (type2Info[`${type}`].uploadTime ?? '--') + '上传') : '未上传'}</div>
                                        </div>
                                    )
                                })
                            }
                        </Spin>
                    </Layout>
                </Layout>
            </Layout>
        )
    }
}
