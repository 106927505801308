import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { withRouter } from 'react-router'
import style from './index.module.scss'
import RealTime from 'components/RealTime';
import ICON_AVATAR from './res/avatar.svg'
import { Menu, Dropdown, Modal, Button } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import moment from 'moment'

const Comp = (props) => {

  const { history, location } = props
  const [name, setName] = useState(null)

  useEffect(() => {
    const userInfoStr =
      window.sessionStorage.getItem('USER_INFO') || window.localStorage.getItem('USER_INFO')
    let userInfo = null
    if (typeof userInfoStr === 'string' && userInfoStr !== '') {
      try {
        userInfo = JSON.parse(userInfoStr)
      } catch (err) { }
    }
    if (!!userInfo) {
      const { name, expire_time } = userInfo
      try {
        if (moment.unix(expire_time).isAfter(moment())) {
          if (typeof name === 'string') setName(name)
        }
      } catch (err) { }
    }
  }, [])

  const nameText = useMemo(() => {
    if (typeof name === 'string') {
      return name
    }
    return '(未登录)'
  }, [name])

  const handleMenuClick = ({ key }) => {
    if (key === '1' && location.pathname !== '/System/PersonalCenter') {
      history.push('/System/PersonalCenter')
    } else if (key === '2') {
      Modal.confirm({
        title: '退出登录?',
        content: '',
        onOk() {
          localStorage.clear()
          sessionStorage.clear()
          history.push('/Login')
        }
      })
    }
  }
  const makeVoice = useCallback(() => {
    const utterThis = new window.SpeechSynthesisUtterance('测试声音');
    window.speechSynthesis.speak(utterThis);
  }, [])

  /** 下拉菜单 */
  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item
        key="1"
        className={`${location.pathname === '/System/PersonalCenter' ? 'active' : ''}`}
      >
        <i className="icon personal-icon" />
        个人中心
      </Menu.Item>
      <Menu.Item key="2">
        <i className="icon logout-icon" />
        退出登录
      </Menu.Item>
    </Menu>
  )

  return (
    <div className={style['wrapper']} style={{ backgroundColor: "rgba(255, 255, 255, 1)" }}>
      <div className="left">
        <div className="text">新晨综合能源服务平台</div>
      </div>
      <div className="right">
        <RealTime />
        <Button type="link" size="small" onClick={makeVoice}>声音</Button>
        <Dropdown
          overlay={menu}
          trigger={['click']}
          placement="bottomRight"
          overlayClassName={style['headerDorpDown']}
          arrow
        >
          <div>
            <img src={ICON_AVATAR} alt="" className="avatar" />
            <span className="user_name">{nameText}</span>
            <DownOutlined />
          </div>
        </Dropdown>
      </div>
    </div>
  )
}

export default withRouter(Comp)
