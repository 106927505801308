import React, { useState } from 'react';
import style from './index.module.scss';
import DomResize from 'components/DomResize';
import ScaleComp from './ScaleComp';

const StandardWidthHeight = {
    width: 1920,
    height: 1080,
};

const Comp = (props) => {
    const [widthHeight] = useState(() => {
        const props_width = props?.width ?? null;
        const props_height = props?.height ?? null;
        if (typeof props_width === 'number' && typeof props_height === 'number') {
            return {
                width: props_width,
                height: props_height,
            }
        };
        return StandardWidthHeight;
    });

    const [innerStyle, setInnerStyle] = useState(null);

    const onResize = (conf) => {
        // console.log(conf, widthHeight);
        // 判断实际长宽和导入长宽的大小，使得实际宽大于导入宽时，不缩放
        // 实际宽度小于导入宽时，缩放（此处后续支持不加入缩放逻辑）
        const {
            width: domWidth,
            // height: domHeight,
        } = conf;
        const { width: userWidth, height: userHeight } = widthHeight;
        if (domWidth >= userWidth) {
            setInnerStyle({
                width: userWidth,
                height: userHeight,
                marginLeft: Math.floor((domWidth - userWidth) / 2),
            });
        } else {
            const parsed_height = userHeight / userWidth * domWidth;
            setInnerStyle({
                width: domWidth,
                height: parsed_height,
            });
        };
    };

    return (
        <div className={style["wrapper"]}>
            <div className="resize_wrapper">
                <DomResize mountedCall={true} onResize={onResize} />
            </div>
            <div className="content scroll_bar_restyle">
                {
                    !!innerStyle && (
                        <div style={innerStyle}>
                            <ScaleComp {...props}>
                                {props.children}
                            </ScaleComp>
                        </div>
                    )
                }
            </div>
        </div>
    );
};

export default Comp;
