import React from 'react';
import { get } from 'common/utils';
import moment from 'moment';
import { Button, DatePicker, Table, Select } from 'antd';
import style from './Content.module.scss';
import DetailModal from './DetailModal.js';
import DomResize from 'components/DomResize';

// const SERVER_URL = "http://139.224.66.172:8084/";
const SERVER_URL = process.env.REACT_APP_SERVER_PATH

const { Option } = Select;

let ALARM_LEVELS = [
    {
        level: 1,
        name: '一级告警',
    }, {
        level: 2,
        name: '二级告警',
    }, {
        level: 3,
        name: '三级告警',
    }
]

export default class Content extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            modalVisible: false,
            systems: [],
            selectAlarm: null,
            volumeOff: true,
            tableLoading: false,
            tableYHeight: null,
        };
    }

    componentDidMount() {
        this.getSystems();
    }

    getSystems = () => {
        get(SERVER_URL + 'api/meta/get_prod_system_list').then(retData => {
            // console.log(retData);
            if (retData.data && retData.data.errcode === 0) {
                this.setState({ systems: retData.data.results });
            };
        });
    }

    onResize = (conf) => {
        // console.log(conf);
        this.setState({
            tableYHeight: conf.height - 200,
        });
    };

    render() {
        const { modalVisible, selectAlarm, tableYHeight } = this.state;
        const { dataSource, filterSysId, filterLevel, dayBegin, dayEnd, total, tableLoading } = this.props;
        const { sysIdOnchange = () => { }, levelOnchange = () => { }, rangeOnchange = () => { }, getAlarmList = () => { }, pageOnchange = () => { } } = this.props;

        return (
            <div className={style["alarm_page_content_wrapper"]}>

                <div className="resize">
                    <DomResize mountedCall={true} onResize={this.onResize} />
                </div>

                <div className="content_wrapper">
                    <div className="header">
                        <Select
                            defaultValue="all"
                            value={filterSysId}
                            style={{ width: 180 }}
                            onChange={sysIdOnchange}
                        >
                            <Option value='all'>全部系统</Option>
                            {
                                this.state.systems.map(({ id, name }) =>
                                    <Option value={id + ''} key={id}>{name}</Option>)
                            }
                        </Select>
                        <Select
                            style={{ width: 144, marginLeft: 24 }}
                            defaultValue="all"
                            value={filterLevel}
                            onChange={levelOnchange}
                        >
                            <Option value='all'>全部告警</Option>
                            {
                                ALARM_LEVELS.map(({ level, name }) =>
                                    <Option value={level} key={level}>{name}</Option>
                                )
                            }
                        </Select>
                        <span style={{ marginLeft: 20 }}>告警时间：</span>
                        <DatePicker.RangePicker
                            value={[dayBegin, dayEnd]}
                            onChange={rangeOnchange}
                            disabledDate={current => current > moment().endOf('day')}
                            style={{ width: 240 }}
                        />
                        <Button type="primary" style={{ marginLeft: 24 }} onClick={getAlarmList}>查询</Button>
                    </div>

                    {
                        typeof tableYHeight === 'number' && !isNaN(tableYHeight) && (
                            <Table
                                loading={tableLoading}
                                dataSource={dataSource}
                                pagination={{
                                    defaultCurrent: 1,
                                    pageSize: 10,
                                    total: total,
                                    onChange: pageOnchange
                                }}
                                scroll={{ y: tableYHeight }}
                                rowClassName={({ time_end }, index) => time_end ? '' : 'not_end_alarm'}
                                columns={[
                                    {
                                        title: '点位名称',
                                        key: 'title',
                                        render: (text, item, index) => {
                                            let name = '';
                                            if (item.alarm_meta) {
                                                if (item.type === 1) {
                                                    name = item.alarm_meta.upper_msg;
                                                } else if (item.type === 2) {
                                                    name = item.alarm_meta.lower_msg;
                                                }
                                            }
                                            const { time_end } = item;
                                            return (
                                                <div className={time_end ? '' : 'not_end_title'}>
                                                    <span>{name}</span>
                                                </div>
                                            );
                                        }
                                    },
                                    {
                                        title: '所属系统',
                                        key: 'system',
                                        render: (text, { alarm_meta: { sys_name } }) => sys_name
                                    },
                                    {
                                        title: '告警类型',
                                        dataIndex: 'type',
                                        render: (text) => {
                                            if (parseInt(text) === 1) {
                                                return '超上限告警';
                                            } else if (parseInt(text) === 2) {
                                                return '超下限告警';
                                            } else if (parseInt(text) === 3) {
                                                return '波动告警';
                                            }
                                        },
                                        align: 'center',
                                    },
                                    {
                                        title: '告警起止时间',
                                        key: 'alarmTime',
                                        render: (text, { time_begin, time_end }, index) => (
                                            <span>{time_begin}<br />{time_end || '—— ——'}</span>
                                        ),
                                        align: 'center',
                                    },
                                    {
                                        title: '持续时长',
                                        dataIndex: 'duration',
                                        align: 'center',
                                    },
                                    {
                                        title: '警告级别',
                                        key: 'level',
                                        render: (text, record) => {
                                            let level_item = ALARM_LEVELS.filter(item => item.level === record.alarm_meta.level)[0];
                                            if (!level_item) {
                                                return '';
                                            } else {
                                                return level_item.name;
                                            }
                                        },
                                        align: 'center',
                                    },
                                    {
                                        title: '操作',
                                        key: 'action',
                                        width: 120,
                                        render: (text, record, index) => (
                                            <Button type="link" onClick={() => this.setState({ selectAlarm: record, modalVisible: true })}>查看</Button>
                                        ),
                                        align: 'center',
                                    },
                                ]}
                            />
                        )
                    }
                    {
                        modalVisible && (
                            <DetailModal
                                data={selectAlarm}
                                onCancel={() => this.setState({ selectAlarm: null, modalVisible: false })}
                            />
                        )
                    }
                </div>
            </div>
        );
    }
}
