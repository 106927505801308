import React from 'react';
import { withRouter } from 'react-router'
import style from './index.module.scss';
import { Button, Spin, Select, DatePicker, Table, message, Input } from 'antd';
import { getColumns, filterConditions as conditions } from './utils';
import DomResize from 'components/DomResize';
import moment from 'moment';
import { get } from 'common/utils';

const { Option } = Select;
const { RangePicker } = DatePicker;

const pageSizeOptions = [10, 20, 30, 50];
class Comp extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            sizeConf: null,
            dataSource: null,
            total: 0,
            page: 1,
            page_size: pageSizeOptions[0],
            columns: getColumns(),
            filterConditions: conditions,
            keyWord: '',
        };
    }

    componentDidMount() {
        this.fetchQueryOpts()
    }

    fetchQueryOpts = () => {
        const types = [2, 3, 4]
        Promise.all(
            types
                .map(v => get(process.env.REACT_APP_SERVER_PATH + "api/xinchen/business/querySysDict", { type: v }))
        )
            .then(retDatas => {
                // console.log(retDatas)
                let n_filterConditions = conditions
                retDatas.some((retData, index) => {
                    if (retData?.data?.error === 0) {
                        const results = retData.data.results ?? []
                        n_filterConditions = n_filterConditions
                            .map(item => {
                                const { queryType, type } = item
                                if (type === 'SELECTOR' && typeof queryType === 'number' && queryType === types[index]) {
                                    // 存在对应条件
                                    let options = results
                                        .map(item => {
                                            const { id } = item;
                                            return Object.assign({}, item, {
                                                value: id
                                            })
                                        })
                                    const { needAll, defaultOptIndex } = item
                                    let allSymbol = null
                                    if (needAll === true) {
                                        allSymbol = Symbol()
                                        options = [
                                            {
                                                name: "全部",
                                                value: "all",
                                                symbol: allSymbol
                                            }
                                        ]
                                            .concat(options)
                                    }
                                    options = options.map((item, index) => Object.assign({}, item, { key: `${index}` }))
                                    let selectedOpt = null
                                    if (typeof defaultOptIndex === 'number') {
                                        selectedOpt = options[defaultOptIndex] ?? null
                                    }
                                    const n_item = Object.assign({}, item, {
                                        options, selectedOpt, allSymbol
                                    })
                                    return n_item
                                }
                                return item
                            })
                    }
                    return false
                })
                this.setState({ filterConditions: n_filterConditions }, this.getData)
            })

    }

    getFilterInfo = () => {
        const { filterConditions } = this.state;
        let attrs = {};
        if (!!filterConditions) {
            attrs = filterConditions
                .reduce((pV, cV) => {
                    const { type, selectedOpt, timeRange, key } = cV;
                    if (type === "SELECTOR" && !!selectedOpt) {
                        const { needAll, allSymbol } = cV
                        if (needAll === true && allSymbol === (selectedOpt?.symbol ?? null)) {
                            return pV
                        }
                        return Object.assign({}, pV, { [key]: selectedOpt?.value ?? null });
                    };
                    if (type === "TIMERANGE-PICKER" && !!timeRange) {
                        return Object.assign({}, pV, {
                            // [key]: [moment(timeRange[0]).format("YYYY-MM-DD"), moment(timeRange[1]).format("YYYY-MM-DD")],
                            [key]: `${moment(timeRange[0]).format("YYYY-MM-DD")}_${moment(timeRange[1]).format("YYYY-MM-DD")}`,
                        });
                    };
                    return pV;
                }, {});
        };
        return attrs;
    };

    getData = () => {
        const { page, page_size, keyWord } = this.state
        const attrs = this.getFilterInfo()
        const conf = Object.assign(
            {},
            {
                pageNum: page,
                pageSize: page_size,
                ...attrs
            },
            (typeof keyWord === 'string' && keyWord !== '') ? { keyWord } : {}
        )
        // console.log(conf, attrs)
        this.setState({ loading: true })
        get(process.env.REACT_APP_SERVER_PATH + 'api/xinchen/business/queryOnlineOrder', conf)
            .then(retData => {
                this.setState({ loading: false })
                // console.log(retData)
                if (retData?.data?.error === 0) {
                    let list = retData.data.results?.list ?? null
                    const total = retData.data.results?.total ?? 0
                    if (!!list) {
                        list = list
                            .map((item, index) => {
                                const n_item = Object.assign({}, item, { key: `${index}` })
                                const { filterConditions } = this.state;
                                (filterConditions ?? [])
                                    .filter(({ type }) => type === 'SELECTOR')
                                    .some(fItem => {
                                        const { key, options } = fItem
                                        const v = n_item[key] ?? null
                                        let name = '--'
                                        if (v !== null) {
                                            name = (options ?? []).filter(({ value }) => value === v)[0]?.name ?? (`--(${v})`)
                                        }
                                        n_item[`${key}-name`] = name
                                        return false
                                    })
                                return n_item
                            })
                    }
                    this.setState({ dataSource: list, total })
                } else {
                    message.warning('查询工单记录失败')
                }
            })
    };

    onResize = (conf) => {
        this.setState({ sizeConf: conf });
    }

    timeRangeOnChange = (index) => {
        return (timeRange) => {
            const { filterConditions } = this.state;
            const n_filterConditions = filterConditions
                .map((item, iIndex) => {
                    if (iIndex === index) {
                        return Object.assign({}, item, { timeRange });
                    };
                    return item;
                });
            this.setState({ filterConditions: n_filterConditions });
        };
    };

    optOnChange = (index) => {
        return (s_key) => {
            const { filterConditions } = this.state;
            const n_filterConditions = filterConditions
                .map((item, iIndex) => {
                    if (iIndex === index) {
                        const { options } = item;
                        const selectedOpt = (options ?? []).filter(({ key }) => key === s_key)[0] ?? null;
                        return Object.assign({}, item, { selectedOpt });
                    };
                    return item;
                });
            this.setState({ filterConditions: n_filterConditions });
        };
    };

    pageOnChange = (page, pageSize) => {
        const { page_size: prevPageSize } = this.state;
        if (prevPageSize !== pageSize) {
            // 页码变化，返回第一页
            this.setState({ page: 1, page_size: pageSize }, this.getData);
        } else {
            this.setState({ page }, this.getData);
        };
    };

    getDataBtn = () => {
        this.setState({ page: 1 }, this.getData);
    };

    inputOnChange = key => {
        return e => {
            const val = e?.target?.value ?? ''
            this.setState({ [key]: val })
        }
    }

    render() {
        const {
            loading, sizeConf, dataSource,
            total, page, page_size, columns, filterConditions,
            keyWord
        } = this.state;

        return (
            <div className={style["wrapper"]}>
                <div className="top">
                    {
                        (filterConditions ?? [])
                            .map((item, index) => {
                                const { name, type, options, timeRange, selectedOpt } = item;
                                if (type === "SELECTOR") {
                                    return (
                                        <div className="item" key={index}>
                                            <div className="title">{name}</div>
                                            <Select
                                                allowClear
                                                placeholder="请输入" style={{ width: 104 }} value={selectedOpt?.key ?? null} onChange={this.optOnChange(index)}
                                                showSearch
                                                optionFilterProp="children"
                                            >
                                                {
                                                    (options ?? [])
                                                        .map((option, optIndex) => {
                                                            const { name, key } = option;
                                                            return (
                                                                <Option value={key} key={optIndex}>{name}</Option>
                                                            );
                                                        })
                                                }
                                            </Select>
                                        </div>
                                    );
                                } else if (type === "TIMERANGE-PICKER") {
                                    return (
                                        <div className="item" key={index}>
                                            <div className="title">{name}</div>
                                            <RangePicker
                                                style={{ width: 247 }}
                                                value={timeRange ?? null} onChange={this.timeRangeOnChange(index)} />
                                        </div>
                                    );
                                };
                                return null;
                            })
                    }
                    <Input placeholder='输入关键词查询工单' value={keyWord} onChange={this.inputOnChange('keyWord')} style={{ width: 264, margin: '12px 12px 0 12px' }} allowClear />
                    <div className="btns">
                        <Button type="primary" shape="round" onClick={this.getDataBtn} style={{ marginLeft: 12 }}>查询</Button>
                    </div>
                </div>
                <div className="bottom">
                    <div className="resize_wrapper">
                        <DomResize mountedCall={true} onResize={this.onResize} />
                    </div>
                    <div className="table_wrapper">
                        {
                            !!sizeConf && (
                                <Table
                                    // rowSelection={rowSelection}
                                    dataSource={dataSource ?? []}
                                    columns={columns}
                                    pagination={{
                                        pageSize: page_size,
                                        total,
                                        current: page,
                                        pageSizeOptions,
                                        onChange: this.pageOnChange,
                                    }}
                                    scroll={{
                                        y: sizeConf.height - 130,
                                    }}
                                />
                            )
                        }
                    </div>
                </div>
                {
                    loading && (
                        <div style={{ position: 'absolute', zIndex: 100, top: 0, right: 0, bottom: 0, left: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(255,255,255,0.7)' }}>
                            <Spin />
                        </div>
                    )
                }
            </div>
        );

    }
}
export default withRouter(Comp);
