import React from 'react';
import { post } from 'common/utils';
import moment from 'moment';
import { Modal, DatePicker, TimePicker, Spin, message } from 'antd';

import style from './DetailModal.module.scss';
const echarts = require('echarts');

// const SERVER_URL = "http://139.224.66.172:8084/";
const SERVER_URL = process.env.REACT_APP_SERVER_PATH

export default class DetailModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            time_begin_date: null,
            time_begin_time: null,
            time_end_date: null,
            time_end_time: null,
            tag_name: null,
        }
    }

    componentDidMount() {
        const { data } = this.props;
        if (!data) return;
        let { time_begin, time_end, alarm_meta: { tag_name } } = data;
        if (!time_end) time_end = moment(time_begin).add(1, 'hours');
        this.setState({
            time_begin_date: moment(time_begin),
            time_begin_time: moment(time_begin),
            time_end_date: moment(time_end),
            time_end_time: moment(time_end),
            tag_name,
        }, this.getChartData);
        // this.getChartData();
    }

    renderChart = dataList => {
        // console.log(dataList);
        var myChart = echarts.init(document.getElementById('myChart'));
        myChart.setOption({
            grid: {
                left: 50,
                top: 20,
                right: 0,
                bottom: 30,
            },
            xAxis: {
                type: 'time',
            },
            yAxis: {
                scale: true,
            },
            tooltip: {
                trigger: 'axis',
            },
            series: [{
                data: dataList
                    .filter(([_, v]) => v !== null),
                type: 'line',
                symbol: 'none',
                lineStyle: {
                    color: '#357CF7',
                    width: 2,
                },
            }],
        });
    }

    getChartData = () => {
        const { data } = this.props;
        if (!data) return;
        const { time_begin_date, time_begin_time, time_end_date, time_end_time, tag_name } = this.state;
        if (!time_begin_date || !time_begin_time || !time_end_date || !time_end_time) {
            message.error('请填写完整时间!');
            return;
        }
        const time_begin = time_begin_date.format('YYYY-MM-DD ') + time_begin_time.format('HH:mm:ss');
        const time_end = time_end_date.format('YYYY-MM-DD ') + time_end_time.format('HH:mm:ss');
        if (moment(time_begin).isSameOrAfter(moment(time_end))) {
            message.error('开始时间应小于结束时间!');
            return;
        }
        // const duration = moment(time_end).diff(moment(time_begin), 'seconds');
        // const interval = Math.ceil(duration / 60);
        this.setState({ loading: true });
        post(SERVER_URL + 'api/data/get_history_data', {
            time_type: 'seconds',
            tag_list: [
                {
                    tag_name,
                    time_begin,
                    time_end,
                    // interval,
                }
            ]
        }).then(retData => {
            // console.log(retData);
            if (retData?.data?.errcode === 0) {
                // console.log(retData);
                this.renderChart(retData.data.results[0].data_list);
            }
            this.setState({ loading: false });
        });
    }

    render() {
        const { loading, time_begin_date, time_begin_time, time_end_date, time_end_time } = this.state;
        const { onCancel = () => { }, data } = this.props;
        if (!data) return;
        const { alarm_meta: { name } } = data;

        return (
            <Modal
                title="告警详情"
                centered
                visible={true}
                onCancel={onCancel}
                width={1200}
                footer={null}
            >
                <div className={style["alarm_modal_wrapper"]}>
                    <div className="header">
                        <div>点位名称: </div>
                        <div>{name}</div>
                        <DatePicker style={{ width: 144, marginLeft: 24 }} value={time_begin_date} onChange={time_begin_date => this.setState({ time_begin_date })} />
                        <TimePicker style={{ width: 88, marginLeft: 8 }} value={time_begin_time} onChange={time_begin_time => this.setState({ time_begin_time })} />
                        <div className="time_link">-</div>
                        <DatePicker style={{ width: 144 }} value={time_end_date} onChange={time_end_date => this.setState({ time_end_date })} />
                        <TimePicker style={{ width: 88, marginLeft: 8 }} value={time_end_time} onChange={time_end_time => this.setState({ time_end_time })} />
                        <div className="btn" onClick={this.getChartData}>
                            <div className="text">查询</div>
                        </div>
                    </div>
                    <Spin tip="Loading..." spinning={loading}>
                        <div id="myChart" className="chart_wrapper" style={{ height: 300 }}></div>
                    </Spin>
                </div>
            </Modal>
        );
    }
}
