import React from 'react'
import { Breadcrumb } from 'antd'
import { withRouter, Link } from 'react-router-dom'
import style from './index.module.scss'

const TopBreadcrumb = withRouter(props => {
  const { breadcrumbList } = props
  console.log('breadcrumbList;', breadcrumbList)
  return (
    <Breadcrumb separator=">" className={style['topBreadcrumb']} style={{ lineHeight: "44px", fontSize: 14 }}>
      {breadcrumbList &&
        breadcrumbList.map((breadcrumbItem, index) => {
          if (!!breadcrumbItem.path) {
            return (
              <Breadcrumb.Item key={index}>
                <Link to={breadcrumbItem.path}>{breadcrumbItem.name}</Link>
              </Breadcrumb.Item>
            )
          }
          return <Breadcrumb.Item key={index}>{breadcrumbItem.name}</Breadcrumb.Item>
        })}
    </Breadcrumb>
  )
})

export default TopBreadcrumb
