import React from 'react';
import { Select, Modal } from 'antd';
import { post } from 'common/utils';
// import style from './index.module.scss';

const { Option } = Select;

export default class SearchSelectModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            defaultData: props.defaultData || null,
            dataList: [],
            searchApi: props?.searchApi ?? null,
        };
    }

    componentDidMount() {
        this.handleSearch("");
    }

    handleSearch = value => {
        const { searchApi } = this.props;
        if (typeof searchApi !== 'string') {
            return;
        };

        let self = this;
        post(searchApi, {
            keyword: value
        }).then((retData) => {
            // console.log(retData);
            let results = retData?.data?.results ?? [];
            self.setState({
                dataList: results
            })
        });
    }

    handleChange = value => {
        this.setState({ value });
    }

    render() {
        const { value, dataList } = this.state;
        const { title, labelKey, valueKey, onCancel, onOk } = this.props;
        return (
            <Modal
                visible={true}
                title={title}
                onOk={_ => {
                    let data = null;
                    dataList.forEach(item => {
                        if (item[valueKey] == value) {
                            data = item;
                        }
                    })
                    onOk && onOk(data);
                }}
                onCancel={_ => onCancel && onCancel()}
            >
                <Select
                    showSearch
                    // className={style['wrapper']}
                    value={this.state.value}
                    placeholder={this.props.placeholder}
                    // style={this.props.style}
                    style={{
                        // maxWidth: "calc(100vw - 142px)",
                        minWidth: "350px",
                        // fontSize: "12px",
                    }}
                    allowClear={true}
                    defaultActiveFirstOption={false}
                    // showArrow={false}
                    filterOption={false}
                    onSearch={this.handleSearch}
                    onChange={this.handleChange}
                    notFoundContent={null}
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                >
                    {
                        dataList.map(item =>
                            <Option key={item[valueKey]}>{typeof labelKey === 'string' ? item[labelKey] : (labelKey.map(key => item[key]).join(" "))}</Option>
                        )
                    }
                </Select>
            </Modal>
        )
    }
}
