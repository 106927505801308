
const id2Config = {
    '1': {
        title: '全厂用电量统计（kwh）'
        , id: 1
        , whiteList: ['Center-HV.HV-1_DKWH']
        , type: 'LINEBAR_CHART_V1'
    }
    , '11': {
        title: '其他点位用电量统计（kwh）'
        , id: 1
        , blackList: ['Center-HV.HV-1_DKWH']
        , type: 'LINEBAR_CHART_V1'
    }
    , '2': {
        title: '压缩空气消耗量统计（m³）'
        , id: 2
        , type: 'LINEBAR_CHART_V1'
    }
    , '3': {
        title: '蒸汽统计（t）'
        , id: 4
        , blackList: ['STEAM_CHAR_DD', 'STEAM_CHAR_CUM']
        , blackListHide: ['STEAM_FLOW_CUM']
        , type: 'LINEBAR_CHART_V1'
    }
    , '4': {
        title: '用水统计-周（t）'
        , id: 3
        , type: 'LINEBAR_CHART_V1'
    }
    , '5': {
        title: '厂区温度统计（℃）'
        , id: 5
        , type: 'LINEBAR_CHART_V1'
    }
    , '6': {
        title: '厂区湿度统计（%）'
        , id: 6
        , type: 'LINEBAR_CHART_V1'
    }
    , '7': {
        title: '厂区冷冻水温度统计（℃）'
        , id: 7
        , type: 'LINEBAR_CHART_V1'
    }
    , '8': {
        title: '机加工区温度统计表（℃）'
        , id: 5
        , type: 'TABLE_CHART_V1'
    }
    , '9': {
        title: '机加工区湿度统计表（%）'
        , id: 6
        , type: 'TABLE_CHART_V1'
    }
    , '10': {
        title: '机加工区冷冻水温度统计表（℃）'
        , id: 7
        , type: 'TABLE_CHART_V1'
    }
}

const colors = [
    'rgba(89, 94, 168, 1)',
    'rgba(74, 155, 135, 1)',
    'rgba(177, 93, 101, 1)',
    'rgba(83, 146, 188, 1)',
    'rgba(196, 158, 112, 1)',
    'rgba(49, 98, 164, 1)',
];

export {
    id2Config
    , colors
}