import React, { useEffect, useRef, useMemo, useCallback } from 'react';
import style from './index.module.scss';
import * as echarts from 'echarts';
import { renderChart } from './utils.js';
import { Spin } from 'antd';
import DomResize from 'components/DomResize';

const default_color = '#40A1E1';

const Comp = (props) => {
    const { config } = props;
    const chartNode = useRef(null);

    const [min, max, color, chartData, metaHorizon, metaVertical] = useMemo(() => {
        const min = props?.config?.y_axis?.lower_limit ?? null;
        const max = props?.config?.y_axis?.upper_limit ?? null;
        const color = props?.config?.color ?? default_color;
        const metaHorizon = props?.config?.metaHorizon ?? null;
        const metaVertical = props?.config?.metaVertical ?? null;
        const chartData = props?.chartData ?? null;
        return [min, max, color, chartData, metaHorizon, metaVertical];
    }, [props]);
    const chartConf = useMemo(() => {
        return { min, max, color, metaHorizon, metaVertical };
    }, [min, max, color, metaHorizon, metaVertical])
    useEffect(() => {
        renderChart(chartNode.current, chartConf, chartData);
    }, [chartConf, chartData]);

    const onResize = useCallback(() => {
        if (!!chartNode.current) {
            let instance = echarts.getInstanceByDom(chartNode.current);
            if (!!instance) {
                instance.resize();
            };
        };
    }, []);

    return (
        <div className={style["wrapper"]}>
            <div className="header">
                <div className="colored_bar"></div>
                <div className="title">{config?.title ?? "--"}</div>
                <div style={{ marginLeft: "auto" }}></div>
                <div className="colored_line_wide" style={{ backgroundColor: color }}></div>
                <div className="item_title">{config?.name ?? "--"}</div>
                <div className="colored_line_dashed" style={{ borderBottomColor: "rgba(251, 62, 104, 1)" }}></div>
                <div className="item_title">拟合趋势</div>
            </div>
            <div className="content">
                <div className="resize_wrapper">
                    <DomResize onResize={onResize} />
                </div>
                <div ref={chartNode} style={{ height: '100%', overflow: 'hidden', position: "relative" }} />
            </div>
            {
                props?.loading === true && (
                    <div style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(255,255,255,0.7)' }}>
                        <Spin />
                    </div>
                )
            }
        </div>
    );
};

export default Comp;
