import { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { message } from 'antd';
import { get } from '../../common/utils';
import { template_uid_2_comp } from './constants.js';
import { id2Config, colors } from './utils';

const Comp = (props) => {
    const { range, id } = props;
    const [type, setType] = useState(null);
    const [config, setConfig] = useState(null);
    useEffect(() => {
        const localConfig = id2Config[id] ?? null
        if (localConfig) {
            const { id: queryId, type, whiteList, blackList } = localConfig
            setType(type)
            get(process.env.REACT_APP_SERVER_PATH + 'api/xinchen/tag/queryMetadataByCategory', {
                category: queryId + "",
            })
                .then((retData) => {
                    // console.log(retData);
                    if (retData?.data?.error === 0) {
                        const meta_list = (retData.data.results ?? [])
                            .filter(item => !!item)
                            // .filter(({ tagName }) => tagName !== "STEAM_CHAR_DD" && tagName !== "STEAM_CHAR_CUM") //hardcode
                            .filter(({ tagName }) => {
                                if (!!whiteList) return whiteList.includes(tagName)
                                if (!!blackList) return !blackList.includes(tagName)
                                return true
                            })
                            .map((item, index) => {
                                const { tagName } = item
                                const nItem = Object.assign(
                                    {}
                                    , item
                                    , {
                                        tag_name: tagName
                                        , y_axis: 'left'
                                        , color: colors[index % colors.length]
                                    }
                                )
                                return nItem
                            })
                        const nConfig = Object.assign({}, localConfig, { meta_list })
                        setConfig(nConfig)
                    } else {
                        message.error("获取信息失败,请刷新重试!");
                    }
                })
        }
    }, [id]);// 外部id变化内部修改相应配置, 但其实如果有这样的需求的话可能需要在外部调节，而不是把id传到内部？
    const BlockComp = template_uid_2_comp[type] || null;
    // console.log(type, config);
    return (!!BlockComp && !!config) && (
        <BlockComp config={config} range={range} id={id} />
    );
};

export default withRouter(Comp);
