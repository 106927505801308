import React from 'react';
import _ from "lodash";
import * as echarts from 'echarts';
import style from './index.module.scss';
import { DEFAULT_OPTION, DEFAULT_LINE_COLOR } from './utils';
import { Spin } from 'antd';
import DomResize from 'components/DomResize';

export default class Comp extends React.Component {

    constructor(props) {
        super(props);
        const hideIdList = (props?.config?.meta_list ?? []).filter(({ tagName }) => (props?.config?.blackListHide ?? []).includes(tagName)).map(({ id }) => id)
        this.state = {
            hideIdList
        };
    }

    componentDidMount() {
        this.updateChart(this.props.config, this.props.lineData);
    }

    componentWillReceiveProps(nextProps) {
        this.updateChart(nextProps.config, nextProps.lineData);
    }

    updateChart = (config, data) => {
        // console.log(config, data);
        if (!this.chartNode) {
            return;
        }

        let option = _.cloneDeep(DEFAULT_OPTION);
        const { hideIdList } = this.state
        option.series = (config?.meta_list ?? [])
            .filter(({ id }) => !hideIdList.includes(id))
            .map((item) => {
                // const item_data = data?.["CNYS.GZGX.ELEC.SCONSM"] ?? [];
                const item_data = data?.[item?.tag_name ?? null] ?? [];
                return {
                    name: item?.name ?? "--",
                    data: item_data,
                    type: item?.type === "bar" ? "bar" : 'line',
                    itemStyle: {
                        color: item?.color ?? DEFAULT_LINE_COLOR,
                    },
                    yAxisIndex: item?.y_axis === "left" ? 0 : 1,
                    symbol: 'none',
                };
            });

        const leftYMax = config?.y_axis_left?.upper_limit ?? null;
        const leftYMin = config?.y_axis_left?.lower_limit ?? null;
        const rightYMax = config?.y_axis_right?.upper_limit ?? null;
        const rightYMin = config?.y_axis_right?.lower_limit ?? null;

        if (!!option?.yAxis?.[0]) {
            option.yAxis[0] = Object.assign(
                {}, option.yAxis[0],
                (typeof leftYMax === 'number' && !isNaN(leftYMax)) ? {
                    max: leftYMax,
                } : {},
                (typeof leftYMin === 'number' && !isNaN(leftYMin)) ? {
                    min: leftYMin,
                } : {},
            );
        };
        if (!!option?.yAxis?.[1]) {
            option.yAxis[1] = Object.assign(
                {}, option.yAxis[1],
                (typeof rightYMax === 'number' && !isNaN(rightYMax)) ? {
                    max: rightYMax,
                } : {},
                (typeof rightYMin === 'number' && !isNaN(rightYMin)) ? {
                    min: rightYMin,
                } : {},
            );
        };

        let instance = echarts.getInstanceByDom(this.chartNode);
        if (!instance) {
            instance = echarts.init(this.chartNode);
        } else {
            instance.clear();
        };

        instance.setOption(option);
    }

    refFUnc = (refNode) => {
        this.chartNode = refNode;
    }

    onResize = () => {
        if (!!this.chartNode) {
            let instance = echarts.getInstanceByDom(this.chartNode);
            if (!!instance) {
                instance.resize();
            };
        };
    }

    hide = item => {
        return () => {
            // console.log(item)
            const { hideIdList } = this.state;
            let nHideIdList = []
            if (hideIdList.includes(item.id)) {
                nHideIdList = hideIdList.filter(v => v !== item.id)
            } else {
                nHideIdList = hideIdList.concat(item.id)
            }
            this.setState({ hideIdList: nHideIdList }, () => {
                this.updateChart(this.props.config, this.props.lineData);
            })
        }
    }

    render() {
        const { config, loading } = this.props;
        const { hideIdList } = this.state

        return (
            <div className={style["wrapper"]}>
                <div className="header">
                    <div className="colored_bar"></div>
                    <div className="title">{config?.title ?? "--"}</div>
                    <div style={{ marginLeft: "auto" }}></div>
                    <div className="right">
                        {
                            (config?.meta_list ?? [])
                                .map((item, index) => {
                                    const { name, color, id } = item;
                                    return (
                                        <div className={"item" + (hideIdList.includes(id) ? ' hide' : '')} key={index} onClick={this.hide(item)}>
                                            <div className="colored_line" style={{ backgroundColor: color ?? DEFAULT_LINE_COLOR }}></div>
                                            <div className="item_title">{name ?? "--"}</div>
                                        </div>
                                    );
                                })
                        }
                    </div>
                </div>
                <div className="content">
                    <div className="resize_wrapper">
                        <DomResize onResize={this.onResize} />
                    </div>
                    <div ref={this.refFUnc} style={{ height: '100%', position: 'relative' }} />
                </div>
                {
                    loading === true && (
                        <div style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(255,255,255,0.7)' }}>
                            <Spin />
                        </div>
                    )
                }
            </div>
        );
    }
}
