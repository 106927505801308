import React, { Component } from 'react';
import { ConfigProvider } from "antd";
import zhCN from 'antd/lib/locale-provider/zh_CN';
import "moment/locale/zh-cn"
import { HashRouter, Switch, Redirect, Route } from "react-router-dom";

// layout
import SysLayout from 'containers/Layout';
import Monitor from 'containers/Monitor';
import HistoryTrend from 'containers/HistoryTrend';
import Orders from 'containers/Orders';
import OutputRecords from 'containers/OutputRecords';
import DailyReport from 'containers/DailyReport';
// 告警
import AlarmPage from 'containers/AlarmPage/AlarmPage';
import AlarmSetting from 'containers/AlarmSetting/Content';

import AlarmPushRuleList from 'containers/AlarmPushRule/RuleList.js'
import AlarmPushRuleDetails from 'containers/AlarmPushRule/RuleDetails.js'

// common
import User from 'containers/PersonMng';
import Role from 'containers/RoleMngSetting';
import PersonalCenter from 'containers/PersonalCenter';
import Login from 'containers/Login'
import Department from 'containers/OrgMng'

import LogCenter from 'containers/LogCenter'

class App extends Component {
    render() {
        return (
            <ConfigProvider locale={zhCN}>
                <HashRouter>
                    <Switch>
                        <Redirect exact from="/" to="/System/Monitor" />
                        <Route exact path="/Login" component={Login} />
                        <Route path="/System">
                            <SysLayout>
                                <Switch>
                                    <Route exact path="/System/Monitor" component={Monitor} />
                                    <Route exact path="/System/HistoryTrend" component={HistoryTrend} />
                                    <Route exact path="/System/Orders" component={Orders} />
                                    <Route exact path="/System/OutputRecords" component={OutputRecords} />
                                    <Route exact path="/System/DailyReport" component={DailyReport} />
                                    {/* for xqds */}
                                    <Route exact path="/System/AlarmPage" component={AlarmPage} />
                                    <Route exact path="/System/AlarmSetting" component={AlarmSetting} />

                                    <Route exact path="/System/AlarmPushRuleList" component={AlarmPushRuleList} />
                                    <Route exact path="/System/AlarmPushRuleDetails/:id" component={AlarmPushRuleDetails} />

                                    <Route exact path="/System/UserList" component={User} />
                                    <Route exact path="/System/Role" component={Role} />
                                    <Route exact path="/System/PersonalCenter" component={PersonalCenter} />
                                    <Route exact path="/System/Department" component={Department} />
                                    <Route exact path="/System/LogCenter" component={LogCenter} />
                                </Switch>
                            </SysLayout>
                        </Route>
                    </Switch>
                </HashRouter>
            </ConfigProvider>
        );
    }
}

export default App;
