import React, { Component } from 'react'
import { Menu, message } from 'antd'
import { withRouter } from 'react-router-dom'
import style from './index.module.scss'
import { get } from 'common/utils';
import { getMyPermissions } from 'common/AuthPermission.js';
const { SubMenu } = Menu
/** 左侧内嵌菜单 目前最多支持三级菜单 */
class LeftMenu extends Component {
  constructor(props) {
    super(props)
    const { location } = props
    this.state = {
      myPermissions: getMyPermissions() ?? [],
      collapsed: true,
      menuList: null,
      openKeys: [], // 当前打开的菜单key集合
      selectedKeys: [], // 当前选中的菜单key
      activeMenu: location.pathname + location.search, // 当前激活的路由
      rootSubmenuKeys: [] // 有子菜单的根结点key集合
    }
  }
  componentDidMount = () => {
    this.getMenu();
    // console.log(this.state.myPermissions);
  }
  getMenu = () => {
    get(process.env.REACT_APP_SERVER_PATH + "api/page_layouts", {
      page: 1,
      page_size: 999,
      key: "MENU_LIST",
    })
      .then((retData) => {
        // console.log(retData);
        let menuContent = null;
        const content = retData?.data?.results?.[0]?.content ?? null;
        if (typeof content === 'string') {
          try {
            menuContent = JSON.parse(content);
          } catch (e) { };
        };
        if (!!menuContent) {
          // console.log(menuContent);
          menuContent = menuContent
            .map((item) => {
              const { icon_class } = item;
              return Object.assign({}, item, {
                icon: (
                  <i className={icon_class} />
                ),
              })
            });
          this.setState({
            menuList: menuContent,
            rootSubmenuKeys: menuContent.filter(item => item.children && item.children.length > 0).map(
              sunMenu => sunMenu.key
            ),
          }, () => {
            // 监听路由变化 重置当前激活的路由
            this.handleSelectedKeys(this.state.activeMenu)

            this.props.history.listen((info) => {
              const { pathname, search } = info;
              // console.log('跳转到', pathname, info)
              this.handleSelectedKeys(pathname + search)
              this.setState({ activeMenu: pathname + search })
            })
          });
        } else {
          message.warning("菜单数据有误，请刷新重试！");
        };
      });
  };
  /** 根据当前激活的路由高亮激活的菜单
   * 目前只追踪到三级菜单
   */
  handleSelectedKeys = pathname => {
    const { menuList } = this.state
    let key = ''
    menuList.some(stMenu => {
      if (stMenu.path === pathname) {
        key = stMenu.key
        return true
      } else if (stMenu.children && stMenu.children.length > 0) {
        stMenu.children.some(ndMenu => {
          if (ndMenu.path === pathname) {
            key = ndMenu.key
            return true
          } else if (ndMenu.children && ndMenu.children.length > 0) {
            ndMenu.children.some(rdMenu => {
              if (rdMenu.path === pathname) {
                key = rdMenu.key
                return true
              }
              return false
            })
          }
          return false
        })
      }
      return false
    })
    this.setState({ selectedKeys: key ? [key] : [] })
  }

  /** 展开/收起 菜单 */
  toggleCollapsed = () => {
    this.setState({ collapsed: !this.state.collapsed })
  }
  /** SubMenu 展开/关闭的回调 只展开当前父级菜单
   * @param keys: string[]
   */
  onOpenChange = keys => {
    const { openKeys, rootSubmenuKeys } = this.state
    const latestOpenKey = keys.find(key => !openKeys.includes(key))
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      this.setState({ openKeys: keys })
    } else {
      this.setState({ openKeys: latestOpenKey ? [latestOpenKey] : [] })
    }
  }

  /** 路由跳转 */
  navigateToPage = (key, path, path_type) => {
    const { activeMenu } = this.state
    const { history } = this.props
    if (!path) {
      message.info('暂未开启，敬请期待')
      return
    }
    if (path_type === "new_page") {
      window.open(path, '_blank');
      return;
    };
    if (path !== activeMenu) {
      this.setState({ selectedKeys: [key] })
      history.push(path)
    }
  }
  render() {
    const {
      collapsed, menuList, openKeys, selectedKeys,
      myPermissions
    } = this.state

    return (
      <div className={`${style['inline-menu']} ${collapsed ? 'close-animate' : 'open-animate'} `}>
        <i className="menu-icon menu-switch" onClick={this.toggleCollapsed} />
        <div className="bottom scroll_bar_restyle">
          <Menu
            openKeys={openKeys}
            selectedKeys={selectedKeys}
            mode="inline"
            theme="dark"
            inlineCollapsed={collapsed}
            onOpenChange={this.onOpenChange}
            subMenuCloseDelay={0.5}
          >
            {
              (menuList ?? [])
                .map(menuItem => {
                  let allow = true;
                  if (!!(menuItem?.permissions ?? null)) {
                    if (menuItem.permissions.length > 0) {
                      allow = menuItem.permissions
                        .some((perm) => myPermissions.includes(perm));
                    };
                  };
                  if (!allow) {
                    return null;
                  };
                  if (menuItem && menuItem.children && menuItem.children.length > 0) {
                    return (
                      <SubMenu key={menuItem.key} icon={menuItem.icon} title={menuItem.title}>
                        {menuItem.children.map(ndMenuItem => {
                          let allow = true;
                          if (!!(ndMenuItem?.permissions ?? null)) {
                            if (ndMenuItem.permissions.length > 0) {
                              allow = ndMenuItem.permissions
                                .some((perm) => myPermissions.includes(perm));
                            };
                          };
                          if (!allow) {
                            return null;
                          };
                          if (ndMenuItem && ndMenuItem.children && ndMenuItem.children.length > 0) {
                            return (
                              <SubMenu key={ndMenuItem.key} title={ndMenuItem.title}>
                                {ndMenuItem.children.map(rdMenuItem => {
                                  let allow = true;
                                  if (!!(rdMenuItem?.permissions ?? null)) {
                                    if (rdMenuItem.permissions.length > 0) {
                                      allow = rdMenuItem.permissions
                                        .some((perm) => myPermissions.includes(perm));
                                    };
                                  };
                                  if (!allow) {
                                    return null;
                                  };
                                  return (
                                    <Menu.Item
                                      key={rdMenuItem.key}
                                      onClick={() =>
                                        this.navigateToPage(rdMenuItem.key, rdMenuItem.path, rdMenuItem.path_type)
                                      }
                                    >
                                      {rdMenuItem.title}
                                    </Menu.Item>
                                  );
                                })}
                              </SubMenu>
                            )
                          } else {
                            return (
                              <Menu.Item
                                key={ndMenuItem.key}
                                onClick={() => this.navigateToPage(ndMenuItem.key, ndMenuItem.path, ndMenuItem.path_type)}
                              >
                                {ndMenuItem.title}
                              </Menu.Item>
                            );
                          }
                        })}
                      </SubMenu>
                    )
                  } else {
                    return (
                      <Menu.Item
                        key={menuItem.key}
                        icon={menuItem.icon}
                        onClick={() => this.navigateToPage(menuItem.key, menuItem.path, menuItem.path_type)}
                      >
                        {menuItem.title}
                      </Menu.Item>
                    );
                  };
                })
            }
          </Menu>
        </div>
      </div>
    )
  }
}
export default withRouter(LeftMenu)
