import { useEffect, useMemo, useState } from 'react';
import Chart from './index.js';
import moment from 'moment';
import { post } from 'common/utils.js';

const Comp = (props) => {
    const { config, range } = props;
    const [data, setData] = useState(null);
    const { meta_list, id } = useMemo(() => {
        const meta_list = config?.meta_list ?? null;
        const id = config?.id ?? null;
        return {
            meta_list, id
        };
    }, [config]);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        // console.log(tag_name, range);
        const tags = (meta_list ?? [])
            .map(({ tag_name }) => tag_name);
        if (tags.length > 0 && !!range && typeof id === 'number') {
            const time_end = moment(range[1]);
            const time_begin = moment(range[0]);
            // 设置按照小时显示行
            const dataSource = []
            let curr_time = moment(moment(time_begin).format('YYYY-MM-DD HH:00:00'))
            let uuid = 0
            if (tags.length === 1 && tags[0] === "CHLWTR_TEMP") {
                // 冷冻水温度只显示固定时间
                while (curr_time.isSameOrAfter(time_begin) && curr_time.isSameOrBefore(time_end)) {
                    if (['08', '11', '14', '17', '20', '02'].includes(curr_time.format("HH"))) {
                        dataSource.push({
                            time: curr_time.format('YYYY-MM-DD HH:00:00')
                            , key: `${uuid++}`
                        })
                    }
                    curr_time.add(1, 'hours')
                }
            } else {
                while (curr_time.isSameOrAfter(time_begin) && curr_time.isSameOrBefore(time_end)) {
                    dataSource.push({
                        time: curr_time.format('YYYY-MM-DD HH:00:00')
                        , key: `${uuid++}`
                    })
                    curr_time.add(1, 'hours')
                }
            }
            // console.log(dataSource)

            setLoading(true);
            let delay = true;
            // post(process.env.REACT_APP_SERVER_PATH + 'api/data/get_history_data', {
            post(process.env.REACT_APP_SERVER_PATH + (id === 7 ? 'api/data/get_history_data' : `api/xinchen/tag/queryAggMetadata?category=${id}`), {
                tag_list: tags
                    .map(tag_name => {
                        return {
                            tag_name,
                            time_begin: moment(time_begin).format('YYYY-MM-DD HH:mm:ss'),
                            time_end: moment(time_end).format('YYYY-MM-DD HH:mm:ss'),
                            interval: 3600,
                        };
                    }),
            })
                .then(retData => {
                    if (!delay) {
                        return;
                    };
                    setLoading(false);
                    // console.log(retData);
                    if ((retData?.data?.error === 0) || (id === 7 && retData?.data?.errcode === 0)) {
                        const time2Info = (retData.data.results ?? [])
                            .reduce((pV, cV) => {
                                const { tag_name, data_list } = cV;
                                (data_list ?? [])
                                    .some(item => {
                                        const [time, value] = item
                                        let p_v = null
                                        try {
                                            let v = parseFloat(value)
                                            if (typeof v === 'number' && !isNaN(v)) {
                                                p_v = v
                                            }
                                        } catch (e) { }
                                        if (typeof p_v === 'number') {
                                            const timeObj = pV?.[time] ?? {}
                                            timeObj[tag_name] = p_v
                                            pV[time] = timeObj
                                        }
                                        return false
                                    })
                                return pV
                            }, {})
                        // console.log(time2Info)
                        dataSource
                            .some(item => {
                                const { time } = item
                                Object.assign(item, time2Info?.[time] ?? {})
                                return false
                            })
                    }
                    const currTime = moment()
                    const nDataSource = dataSource.filter(({ time }) => moment(time).isBefore(currTime))
                    setData(nDataSource)
                });
            return () => {
                delay = false;
            };
        } else {
            setData(null);
        };
    }, [meta_list, id, range]);
    return (
        <Chart
            config={config}
            data={data}
            loading={loading}
        />
    );
};

export default Comp;
