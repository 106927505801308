import React, { useEffect, useMemo, useState } from 'react';
import Chart from './index.js';
import moment from 'moment';
import { post } from 'common/utils.js';

const Comp = (props) => {
    const { config, range } = props;
    const [chartData, setChartData] = useState(null);
    const metaInfo = useMemo(() => {
        const metaHorizon = config?.metaHorizon ?? null;
        const metaVertical = config?.metaVertical ?? null;
        if (!!metaHorizon && !!metaVertical) {
            return {
                metaHorizon,
                metaVertical,
            };
        };
        return null;
    }, [config]);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        // console.log(range);
        if (!!metaInfo && !!range) {
            const { metaHorizon, metaVertical } = metaInfo;
            const time_end = moment(range[1]);
            const time_begin = moment(range[0]);
            setLoading(true);
            let delay = true;
            post(process.env.REACT_APP_SERVER_PATH + 'pyapi/data/get_scatter_points', {
                interval_sec: 3600,
                time_begin: moment(time_begin).format('YYYY-MM-DD HH:mm:ss'),
                time_end: moment(time_end).format('YYYY-MM-DD HH:mm:ss'),
                tag_x: metaHorizon.tag_name,
                tag_y: metaVertical.tag_name,
            })
                .then(retData => {
                    if (!delay) {
                        return;
                    };
                    setLoading(false);
                    // console.log(retData);
                    if (retData?.data?.errcode === 0) {
                        setChartData(retData.data.results ?? null);
                        return;
                    };
                    setChartData(null);
                });
            return () => {
                delay = false;
            };
        } else {
            setChartData(null);
        };
    }, [metaInfo, range]);
    return (
        <Chart
            config={config}
            chartData={chartData}
            loading={loading}
        />
    );
};

export default Comp;
