import React from 'react';
import style from './index.module.scss';
import CompWrapper from '../Comp/wrapper';

class Comp extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {

        return (
            <div className={style['wrapper']}>
                <div className="display_block">
                    <CompWrapper />
                </div>
                <div className="edit_block">
                </div>
            </div>
        )
    }
};

export default Comp;
