import moment from 'moment';

let uuid = 0

const tabs = [
    {
        name: '能源趋势'
        , type: 'date'
        , defaultRange: [30, 'days']
        , config: {
            "layout": [
                {
                    "i": "1",
                    "x": 0,
                    "y": 0,
                    "w": 2,
                    "h": 2
                }
                , {
                    "i": "11",
                    "x": 2,
                    "y": 0,
                    "w": 2,
                    "h": 2
                }
                , {
                    "i": "2",
                    "x": 0,
                    "y": 2,
                    "w": 2,
                    "h": 2
                }
                , {
                    "i": "3",
                    "x": 2,
                    "y": 2,
                    "w": 2,
                    "h": 2
                }
            ],
            "grid_config": {
                "row_height": 188,
                "cols": {
                    "lg": 4,
                    "md": 4,
                    "sm": 4,
                    "xs": 4,
                    "xxs": 4
                },
                "breakpoints": {
                    "lg": 1200,
                    "md": 996,
                    "sm": 768,
                    "xs": 480,
                    "xxs": 0
                },
                "min_height": 2,
                "min_width": 2
            }
        }
    }
    , {
        name: '用水趋势'
        , type: 'date'
        , defaultRange: [90, 'days']
        , config: {
            "layout": [
                {
                    "i": "4",
                    "x": 0,
                    "y": 0,
                    "w": 4,
                    "h": 2
                }
            ],
            "grid_config": {
                "row_height": 188,
                "cols": {
                    "lg": 4,
                    "md": 4,
                    "sm": 4,
                    "xs": 4,
                    "xxs": 4
                },
                "breakpoints": {
                    "lg": 1200,
                    "md": 996,
                    "sm": 768,
                    "xs": 480,
                    "xxs": 0
                },
                "min_height": 2,
                "min_width": 2
            }
        }
    }
    , Object.assign(
        {
            name: '温湿度趋势'
            , type: 'hour'
            , defaultRange: [3, 'days']
        }
        , (() => {
            const subTabs = [
                {
                    name: '温度'
                    , config: {
                        "layout": [
                            {
                                "i": "5",
                                "x": 0,
                                "y": 0,
                                "w": 4,
                                "h": 2
                            }
                            , {
                                "i": "8",
                                "x": 0,
                                "y": 2,
                                "w": 4,
                                "h": 2
                            }
                        ],
                        "grid_config": {
                            "row_height": 188,
                            "cols": {
                                "lg": 4,
                                "md": 4,
                                "sm": 4,
                                "xs": 4,
                                "xxs": 4
                            },
                            "breakpoints": {
                                "lg": 1200,
                                "md": 996,
                                "sm": 768,
                                "xs": 480,
                                "xxs": 0
                            },
                            "min_height": 2,
                            "min_width": 2
                        }
                    }
                }
                , {
                    name: '湿度'
                    , config: {
                        "layout": [
                            {
                                "i": "6",
                                "x": 0,
                                "y": 0,
                                "w": 4,
                                "h": 2
                            }
                            , {
                                "i": "9",
                                "x": 0,
                                "y": 2,
                                "w": 4,
                                "h": 2
                            }
                        ],
                        "grid_config": {
                            "row_height": 188,
                            "cols": {
                                "lg": 4,
                                "md": 4,
                                "sm": 4,
                                "xs": 4,
                                "xxs": 4
                            },
                            "breakpoints": {
                                "lg": 1200,
                                "md": 996,
                                "sm": 768,
                                "xs": 480,
                                "xxs": 0
                            },
                            "min_height": 2,
                            "min_width": 2
                        }
                    }
                }
                , {
                    name: '冷冻水温度'
                    , config: {
                        "layout": [
                            {
                                "i": "7",
                                "x": 0,
                                "y": 0,
                                "w": 4,
                                "h": 2
                            }
                            , {
                                "i": "10",
                                "x": 0,
                                "y": 2,
                                "w": 4,
                                "h": 2
                            }
                        ],
                        "grid_config": {
                            "row_height": 188,
                            "cols": {
                                "lg": 4,
                                "md": 4,
                                "sm": 4,
                                "xs": 4,
                                "xxs": 4
                            },
                            "breakpoints": {
                                "lg": 1200,
                                "md": 996,
                                "sm": 768,
                                "xs": 480,
                                "xxs": 0
                            },
                            "min_height": 2,
                            "min_width": 2
                        }
                    }
                }
            ].map(item => Object.assign({}, item, { key: `${uuid++}` }))
            return {
                subTabs
                , selectedTab: subTabs[0]
            }
        })()
    )
].map(item => Object.assign({}, item, { key: `${uuid++}` }))

const getHisDefaultRange = (conf) => {
    const { defaultRange: [period, periodUnit] } = conf
    const curr = moment();
    const lastTime = moment(curr).subtract(period, periodUnit);
    return [moment(moment(lastTime).format("YYYY-MM-DD 00:00:00")), moment(moment(curr).format("YYYY-MM-DD 23:59:59"))];
}

export {
    tabs
    , getHisDefaultRange
}