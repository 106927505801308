import React from 'react'
import { post } from 'common/utils'
import moment from 'moment'
import Header from './Header'
import Content from './Content'
import style from './AlarmPage.module.scss'
import TopBreadcrumb from 'components/TopBreadCrumb'

// const SERVER_URL = "http://139.224.66.172:8084/";
const SERVER_URL = process.env.REACT_APP_SERVER_PATH

export default class AlarmPage extends React.Component {
  constructor(props) {
    super(props)
    const { defaultSwitch } = props
    this.state = {
      is_finished: null || defaultSwitch,
      unfinishedNum: null,
      dataSource: [],
      page: 1,
      total: 0,
      pageSize: 10,
      filterSysId: 'all',
      filterLevel: 'all',
      dayBegin: null,
      dayEnd: null,
      tableLoading: false,
    }
  }

  componentDidMount() {
    this.getUnfinishedAlarms()
    this.getAlarmList()
  }

  getAlarmList = () => {
    const { is_finished } = this.state;
    const { page, pageSize, filterSysId, filterLevel, dayBegin, dayEnd } = this.state;
    let self = this;
    this.setState({ tableLoading: true });
    post(SERVER_URL + 'api/alarm/alarm_events/list', {
      page,
      pageSize,
      is_finished,
      sys_id: filterSysId === 'all' ? undefined : filterSysId,
      level: filterLevel === 'all' ? undefined : filterLevel,
      time_begin: dayBegin ? dayBegin.format('YYYY-MM-DD 00:00:00') : undefined,
      time_end: dayEnd ? dayEnd.format('YYYY-MM-DD 23:59:59') : undefined
    }).then(retData => {
      self.setState({ tableLoading: false });
      // console.log(retData);
      if (retData.data && retData.data.errcode === 0) {
        const dataSource = retData.data.results
        dataSource.forEach(item => (item.key = item.id))
        this.setState({
          dataSource: retData.data.results.map(item => ({
            ...item,
            key: item.id,
            duration: this.alarmDur(item)
          })),
          page,
          total: retData.data.total
        })
      }
    })
  }

  getUnfinishedAlarms = () => {
    post(SERVER_URL + 'api/alarm/alarm_events/list', {
      page: 1,
      page_size: 0,
      is_finished: false
    }).then(retData => {
      // console.log(retData);
      if (retData.data && retData.data.errcode === 0) {
        this.setState({ unfinishedNum: retData.data.total })
      }
    })
  }

  statusOnChange = (is_finished = null) => {
    this.getUnfinishedAlarms()
    this.setState({ is_finished }, this.getAlarmList)
    // console.log(is_finished);
  }

  alarmDur = ({ time_begin, time_end }) => {
    if (time_begin && time_end) {
      time_begin = moment(time_begin, 'YYYY-MM-DD HH:mm:ss')
      time_end = moment(time_end, 'YYYY-MM-DD HH:mm:ss')
      const duration = moment.duration(time_end.diff(time_begin))
      // console.log(time_end.diff(time_begin, 'days'), duration.hours(), duration.minutes(), duration.seconds());
      const diffDays = time_end.diff(time_begin, 'days')
      return (
        (diffDays === 0 ? '' : `${diffDays}天 `) +
        [duration.hours(), duration.minutes(), duration.seconds()]
          .map(v => (v < 10 ? `0${v}` : v))
          .join(':')
      )
    }
    return ''
  }

  render() {
    const {
      unfinishedNum,
      dataSource,
      filterSysId,
      filterLevel,
      dayBegin,
      dayEnd,
      total,
      tableLoading,
    } = this.state
    const { tabRect } = this.props
    const breadcrumbList = [{ name: '告警' }, { name: '告警统计' }]
    let tabs_left = null
    if (!!tabRect) {
      if (typeof tabRect.width === 'number') {
        tabs_left = 72 + tabRect.width + 40
      }
    }
    const { customStyle, hideHeader } = this.props
    return (
      <div className={style['alarm_page_wrapper']} style={customStyle}>
        <div
          className="tabs_wrapper"
          style={
            (typeof tabs_left === 'number' ? { left: tabs_left } : {},
              { display: hideHeader ? 'none' : '' })
          }
        >
          <TopBreadcrumb breadcrumbList={breadcrumbList} />
          <Header statusOnChange={this.statusOnChange} unfinishedNum={unfinishedNum} />
        </div>
        <div className="content_wrapper">
          <Content
            dataSource={dataSource}
            filterSysId={filterSysId}
            filterLevel={filterLevel}
            dayBegin={dayBegin}
            dayEnd={dayEnd}
            total={total}
            tableLoading={tableLoading}
            sysIdOnchange={filterSysId => this.setState({ filterSysId })}
            levelOnchange={filterLevel => this.setState({ filterLevel })}
            rangeOnchange={dateRange =>
              this.setState({
                dayBegin: dateRange == null ? null : dateRange[0],
                dayEnd: dateRange == null ? null : dateRange[1]
              })
            }
            getAlarmList={this.getAlarmList}
            pageOnchange={page => this.setState({ page }, this.getAlarmList)}
          />
        </div>
      </div>
    )
  }
}
