import React from 'react';
import { Typography, Select, Space, Input, Popover, Tabs, Table, Radio, Button, message, InputNumber } from 'antd';
import style from './index.module.scss';
import LineChartWrapper from '../Comp/wrapper';
import { SwatchesPicker } from 'react-color';
import { withRouter } from 'react-router';
import { get } from 'common/utils';
import { defaultItemConf, defaultYAxis } from './utils';
import moment from 'moment';

const { Text } = Typography;
const { Option } = Select;
const { TabPane } = Tabs;


const range = (
    () => {
        const curr = moment();
        const lastTime = moment(curr).subtract(1, 'weeks');
        return [lastTime, curr];
    }
)();

class Comp extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            title: props?.data?.config?.title ?? null,
            yAxisLeft: props?.data?.config?.y_axis_left ?? defaultYAxis,
            yAxisRight: props?.data?.config?.y_axis_right ?? defaultYAxis,
            metaList: props?.data?.config?.meta_list ?? [],
            metaOptions: [],
            selectedOption: null,
            selectedMetaKey: null,
        };
    }

    componentDidMount() {
        let { registerDataFunc } = this.props;
        if (!!registerDataFunc) {
            registerDataFunc(() => {
                return Promise.resolve(this.getConf());
            });
        }
        this.handleSearch("");
    }

    handleSearch = value => {
        get(process.env.REACT_APP_SERVER_PATH + `api/metadata/queryUserMetadata?pageNum=1&pageSize=10&keyWord=${encodeURI(value ?? "")}`, {})
            .then((retData) => {
                // console.log(retData);
                if (retData?.data?.error === 0) {
                    const metaOptions = (retData?.data?.results?.list ?? [])
                        .map(item => {
                            const { tagName } = item;
                            const n_item = Object.assign(
                                {},
                                item,
                                {
                                    tag_name: tagName
                                },
                            );
                            return n_item;
                        });
                    this.setState({ metaOptions });
                    return;
                };
                this.setState({ metaOptions: [] });
            });
    };

    addMeta = meta => {
        const { metaList, metaOptions } = this.state;
        const nextSelectedOption = (metaOptions ?? [])
            .filter(({ tag_name }) => tag_name === meta)?.[0] ?? null;
        this.setState({ selectedOption: nextSelectedOption });
        let nextMetaList = [].concat(metaList ?? []);
        if (!!nextSelectedOption) {
            if (!nextMetaList.map(({ tag_name }) => tag_name).includes(nextSelectedOption?.tag_name ?? null)) {
                // 未有
                // 提示添加成功
                const addonMeta = Object.assign({}, nextSelectedOption, defaultItemConf);
                nextMetaList = nextMetaList.concat([addonMeta]);
                this.setState({ metaList: nextMetaList });
                message.success(`成功添加点位【${addonMeta?.name ?? "--"}】`);
            } else {
                // 已有
                // 提示已有
                message.warning("已有该点位，请勿重复添加!");
            };
        };
        // 选择好以直接后添加到下面的table中去，不过需要判断一下是否重复添加，如果重复添加，需要提示并不加入
    }

    getConf = () => {
        const { title, yAxisLeft, yAxisRight, metaList } = this.state;
        return {
            title,
            y_axis_left: yAxisLeft,
            y_axis_right: yAxisRight,
            meta_list: metaList,
        };
    };

    delete = (item) => {
        return () => {
            const { metaList } = this.state;
            const nextMetaList = metaList.filter(({ tag_name }) => tag_name !== (item?.tag_name ?? null));
            this.setState({ metaList: nextMetaList });
        };
    };

    rowOnClick = (item) => {
        return () => {
            // console.log(item);
            this.setState({ selectedMetaKey: item?.tag_name ?? null });
        };
    }

    onRow = (record, index) => {
        return {
            style: { cursor: "pointer" },
            onClick: this.rowOnClick(record),
        };
    }

    getSelectedMeta = () => {
        const { selectedMetaKey, metaList } = this.state;
        const selectedMeta = metaList
            .filter(({ tag_name }) => tag_name === selectedMetaKey)[0] ?? null;
        return selectedMeta;
    };

    updateItemValue = (item, key, value) => {
        const { metaList } = this.state;
        const nextMetaList = metaList
            .map((meta) => {
                if (meta?.tag_name === item?.tag_name) {
                    return Object.assign(
                        {},
                        meta,
                        {
                            [key]: value,
                        },
                    );
                };
                return meta;
            });
        this.setState({ metaList: nextMetaList });
    }

    onChangeComplete = (item) => {
        return (color) => {
            if (color) {
                this.updateItemValue(item, "color", color.hex);
            }
        };
    }

    typeOnChange = (item) => {
        return (e) => {
            const type = e.target.value;
            this.updateItemValue(item, "type", type);
        };
    }

    yPosOnChange = (item) => {
        return (e) => {
            const y_axis = e.target.value;
            this.updateItemValue(item, "y_axis", y_axis);
        };
    }

    yMinMaxOnChange = (pos, minMax) => {
        return (value) => {
            const { [pos]: obj } = this.state;
            const nextObj = Object.assign({}, obj, { [minMax]: value });
            this.setState({ [pos]: nextObj });
        };
    }

    render() {
        let self = this;
        let { title, yAxisLeft, yAxisRight, metaOptions, metaList, selectedMetaKey, selectedOption } = this.state;

        const selectedMeta = this.getSelectedMeta();

        return (
            <div className={style['wrapper']}>
                <div className="display_block">
                    <LineChartWrapper config={this.getConf()} range={range} />
                </div>
                <div className="edit_block">
                    <Tabs defaultActiveKey="1" tabPosition="left">
                        <TabPane tab="基本配置" key="1">
                            <Space direction="vertical">
                                <Space>
                                    <Text>标题</Text>
                                    <Input placeholder="请输入标题名" value={title} onChange={e => this.setState({ title: e.target.value })} style={{ width: 200 }} />
                                </Space>
                                <Space style={{ marginTop: 10 }}>
                                    <Text>左Y轴</Text>
                                    <Text>上限：</Text>
                                    <InputNumber placeholder="请输入上限" value={yAxisLeft.upper_limit} onChange={this.yMinMaxOnChange("yAxisLeft", "upper_limit")} style={{ width: 100 }} />
                                    <Text style={{ marginLeft: 10 }}>下限：</Text>
                                    <InputNumber placeholder="请输入下限" value={yAxisLeft.lower_limit} onChange={this.yMinMaxOnChange("yAxisLeft", "lower_limit")} style={{ width: 100 }} />
                                </Space>
                                <Space style={{ marginTop: 10 }}>
                                    <Text>右Y轴</Text>
                                    <Text>上限：</Text>
                                    <InputNumber placeholder="请输入上限" value={yAxisRight.upper_limit} onChange={this.yMinMaxOnChange("yAxisRight", "upper_limit")} style={{ width: 100 }} />
                                    <Text style={{ marginLeft: 10 }}>下限：</Text>
                                    <InputNumber placeholder="请输入下限" value={yAxisRight.lower_limit} onChange={this.yMinMaxOnChange("yAxisRight", "lower_limit")} style={{ width: 100 }} />
                                </Space>
                            </Space>
                        </TabPane>
                        <TabPane tab="数据项" key="2">
                            <Space>
                                <Space className="leftMetaList" direction="vertical">
                                    <Space className="meta-options-line">
                                        <Text>选择点位：</Text>
                                        <Select
                                            allowClear
                                            showSearch
                                            value={selectedOption?.tag_name ?? null}
                                            style={{ width: 450 }}
                                            defaultActiveFirstOption={false}
                                            showArrow={false}
                                            filterOption={false}
                                            onSearch={this.handleSearch}
                                            onChange={this.addMeta}
                                            notFoundContent={null}
                                        >
                                            {metaOptions.map((item, index) => <Option value={item.tag_name} key={index}>{item.name} {item.tag_name}</Option>)}
                                        </Select>
                                    </Space>
                                    <Table
                                        dataSource={metaList}
                                        rowKey="tag_name"
                                        locale={{ emptyText: "请添加点位" }}
                                        columns={[
                                            {
                                                title: "序号",
                                                key: "no",
                                                render: (text, r, index) => {
                                                    return index + 1;
                                                },
                                                width: 45,
                                                align: "center",
                                            },
                                            {
                                                title: "名称",
                                                dataIndex: "name",
                                                // width: 250,
                                            },
                                            {
                                                title: "点位号",
                                                dataIndex: "tag_name",
                                                width: 250,
                                            },
                                        ]}
                                        size="small"
                                        scroll={{
                                            y: 200,
                                        }}
                                        pagination={false}
                                        rowSelection={{
                                            type: "radio",
                                            columnWidth: 0,
                                            renderCell: () => null,
                                            selectedRowKeys: typeof selectedMetaKey === 'string' ? [selectedMetaKey] : [],
                                        }}
                                        onRow={this.onRow}
                                    />
                                </Space>
                                <Space className="rightConf" direction="vertical">
                                    {!!selectedMeta && <>
                                        <Space>
                                            <Text>名称：</Text>
                                            <Input
                                                value={selectedMeta.name}
                                                onChange={e => { selectedMeta.name = e.target.value; self.forceUpdate(); }}
                                                style={{ width: 200 }}
                                            />
                                        </Space>
                                        <Space>
                                            <Text>图表类型：</Text>
                                            <Radio.Group
                                                onChange={this.typeOnChange(selectedMeta)}
                                                value={selectedMeta.type}
                                            >
                                                <Radio value="line">折线图</Radio>
                                                <Radio value="bar">柱状图</Radio>
                                            </Radio.Group>
                                        </Space>
                                        {/*<Space>
                                        <Text>单位：</Text>
                                        <Input
                                            value={selectedMeta.unit}
                                            onChange={e=>{selectedMeta.unit=e.target.value; self.forceUpdate();}}
                                            style={{ width: 100 }}
                                        />
                                    </Space>*/}
                                        <Space>
                                            <Text>线条颜色</Text>
                                            <Popover
                                                title="选择颜色"
                                                // trigger="click"
                                                placement="right"
                                                content={(
                                                    <Space direction="vertical">
                                                        <SwatchesPicker
                                                            color={selectedMeta.color}
                                                            onChangeComplete={this.onChangeComplete(selectedMeta)}
                                                        />
                                                    </Space>
                                                )}
                                            >
                                                <div style={{ width: 100, height: 30, backgroundColor: selectedMeta.color, borderRadius: 5, cursor: 'pointer' }} />
                                            </Popover>
                                        </Space>
                                        <Space>
                                            <Text>Y轴位置：</Text>
                                            <Radio.Group
                                                onChange={this.yPosOnChange(selectedMeta)}
                                                value={selectedMeta.y_axis}
                                            >
                                                <Radio value="left">左轴</Radio>
                                                <Radio value="right">右轴</Radio>
                                            </Radio.Group>
                                        </Space>
                                        <Button danger onClick={this.delete(selectedMeta)}>删除</Button>
                                    </>}
                                </Space>
                            </Space>
                        </TabPane>
                    </Tabs>
                </div>
            </div>
        )
    }
};

export default withRouter(Comp);
