import { significantNum } from "common/utils";
import moment from "moment";

const getColumns = (conf) => {
    const meta_list = conf?.meta_list ?? null;
    return [
        {
            title: "时间/测湿点",
            dataIndex: "time",
            align: "center",
            render: t => {
                return moment(t).format('MM-DD HH:00')
            }
        },
    ]
        .concat(
            (meta_list ?? [])
                .map(({ tag_name, name }) => {
                    return {
                        title: name,
                        dataIndex: tag_name,
                        align: "center"
                        , render: t => {
                            const p_v = typeof t === "number" ? significantNum(t) : "--"
                            return p_v
                        }
                    };
                })
        );
};

const mockDataSource = new Array(20)
    .fill(null)
    .map((v, index) => {
        return Object.assign(
            {},
            {
                key: `${index}`,
                time: `12-10 ${index}:00`,
            },
            ...new Array(10)
                .fill(null)
                .map((c_v, c_index) => {
                    return {
                        [`val_${c_index + 1}`]: 27.8,
                    };
                }),
        );
    });

export {
    getColumns,
    mockDataSource,
};