import style from './index.module.scss'
import CusModal from '../CusModal';
import { useCallback, useEffect, useRef, useState } from 'react';
import { message, Select, Spin } from 'antd';
import { get, post } from 'common/utils';

const { Option } = Select;
const blank_func = () => { };

const Comp = (props) => {
  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState(null)
  const [selectedOption, setSelectedOption] = useState(null)
  const fetchPoints = useCallback((keyWord = '') => {
    get(process.env.REACT_APP_SERVER_PATH + 'api/xinchen/tag/queryMetadata', {
      keyWord,
      pageNum: 1,
      pageSize: 10,
    })
      .then(retData => {
        // console.log(retData)
        let n_options = null
        if (retData?.data?.error === 0) {
          n_options = retData.data.results?.list ?? null
          if (!!n_options) {
            n_options = n_options.map((item, index) => Object.assign({}, item, { key: `${index}` }))
          }
          setOptions(n_options)
        } else {
          message.warning('获取点位列表失败')
        }
      })
  }, [])
  const fetchPointsRef = useRef(fetchPoints)
  useEffect(() => {
    fetchPointsRef.current()
  }, [])
  const handleSearch = useCallback(value => {
    setSelectedOption(null)
    fetchPointsRef.current(value)
  }, [])
  const addMeta = useCallback(selectedKey => {
    const n_selectedOption = (options ?? []).filter(({ key }) => key === selectedKey)[0] ?? null
    setSelectedOption(n_selectedOption)
  }, [options])
  const onCancel = useCallback((refresh = false) => {
    const func = props?.onCancel ?? blank_func;
    func(refresh);
  }, [props]);
  const { tagGroupId } = props
  const onOk = useCallback(() => {
    // console.log("onOk", selectedOption);
    if (!!selectedOption) {
      if (typeof tagGroupId === 'number') {
        setLoading(true)
        post(process.env.REACT_APP_SERVER_PATH + 'api/xinchen/tag/tagGroupMetadata', {
          tagGroupId, tagName: selectedOption.tagName
        })
          .then(retData => {
            setLoading(false)
            // console.log(retData)
            if (retData?.data?.error === 0) {
              message.success('新增点位成功')
              onCancel(true)
            } else {
              message.warning(retData?.data?.results ?? '新增点位失败')
            }
          })
      }
    } else {
      message.warning('请选择点位')
    }
  }, [selectedOption, tagGroupId, onCancel]);
  return (
    <CusModal
      title="新增监控点位"
      width={400}
      visible={true}
      onCancel={onCancel}
      onOk={onOk}
      cancelButtonProps={{ shape: "round" }}
      okButtonProps={{ shape: "round" }}
      centered
    >
      <div className={style['wrapper']}>
        <div className='left'>点位名称</div>
        <Select
          allowClear
          showSearch
          filterOption={false}
          value={selectedOption?.key ?? null}
          onSearch={handleSearch}
          onChange={addMeta}
          placeholder='请选择点位'
          style={{ width: 0, flexGrow: 1 }}
        >
          {(options ?? []).map(({ key, name, tagName }, index) => <Option value={key} key={index}>{name} {tagName}</Option>)}
        </Select>
        {
          loading === true && (
            <div style={{ position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(255,255,255,0.7)' }}>
              <Spin />
            </div>
          )
        }
      </div>
    </CusModal>
  )
}

export default Comp;
