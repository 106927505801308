import { Button, Tabs, Modal, Spin, message } from 'antd';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import style from './index.module.scss';
import DomResize from 'components/DomResize';
import ICON_CLOSE from './res/close.svg';
import ModalGroup from './ModalGroup';
import ModalPoint from './ModalPoint';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { get, post, significantNum } from 'common/utils';
import ScaleWrapper from 'components/ScaleWrapper'
import DiagramChart from './DiagramChart';

const { TabPane } = Tabs;

const Comp = () => {
    const [loading, setLoading] = useState(false)

    const [groups, setGroups] = useState(null)
    const [activeGroup, setActiveGroup] = useState(null)
    const activeGroupId = useMemo(() => {
        return activeGroup?.id ?? null
    }, [activeGroup])
    const [points, setPoints] = useState(null)
    const [tag2Vals, setTag2Vals] = useState(null)
    useEffect(() => {
        let delay = true
        let fetching = false
        const tag_name_list = (points ?? []).map(({ tagName }) => tagName)
        let myInterval = null
        if (tag_name_list.length > 0) {
            const func = () => {
                if (!fetching) {
                    fetching = true
                    post(process.env.REACT_APP_SERVER_PATH + 'api/data/get_values_before_time', {
                        tag_name_list
                    })
                        .then(retData => {
                            if (delay === true) {
                                fetching = false
                                if (retData?.data?.errcode === 0) {
                                    const n_tag2Vals = retData.data.results ?? null
                                    setTag2Vals(n_tag2Vals)
                                };
                            }
                        })
                }
            };
            func()
            myInterval = setInterval(func, 10000)
        }
        return () => {
            clearInterval(myInterval)
            delay = false
        }
    }, [points])
    const fetchPoints = useCallback(tagGroupId => {
        if (typeof tagGroupId === 'number') {
            setLoading(true)
            get(process.env.REACT_APP_SERVER_PATH + "api/xinchen/tag/queryTagGroupMetadata", { tagGroupId })
                .then(retData => {
                    setLoading(false)
                    // console.log(retData)
                    let n_points = null;
                    if (retData?.data?.error === 0) {
                        n_points = retData.data.results ?? null
                    } else {
                        message.warning('点位数据获取失败')
                    }
                    setPoints(n_points)
                })
        } else {
            setPoints(null)
        }
    }, [])
    const fetchPointsRef = useRef(fetchPoints)
    useEffect(() => {
        fetchPointsRef.current(activeGroupId)
    }, [activeGroupId])
    const fetchGroups = useCallback(() => {
        setLoading(true)
        get(process.env.REACT_APP_SERVER_PATH + 'api/xinchen/tag/group')
            .then(retData => {
                setLoading(false)
                // console.log(retData)
                let n_groups = null
                if (retData?.data?.error === 0) {
                    n_groups = (retData?.data?.results ?? []).map((item, index) => Object.assign({}, item, { key: `${index}` }))
                }
                setGroups(n_groups)
                setActiveGroup(prevActiveGroup => {
                    const n_activeGroup = (n_groups ?? []).filter(({ id }) => id === (prevActiveGroup?.id ?? null))[0] ?? (n_groups?.[0] ?? null);
                    return n_activeGroup;
                })
            })
    }, [])
    const fetchGroupsRef = useRef(fetchGroups)
    useEffect(() => {
        fetchGroupsRef.current()
    }, [])

    const [width, setWidth] = useState(0);
    const onResize = useCallback(conf => {
        const n_w = (conf?.width ?? 0) - 36;
        setWidth(n_w > 0 ? n_w : 0);
    }, []);
    const [createGroupInfo, setCreateGroupInfo] = useState(null);
    const createGroup = useCallback(() => {
        setCreateGroupInfo({});
    }, []);
    const [addPointInfo, setAddPointInfo] = useState(null);
    const addPoint = useCallback(() => {
        setAddPointInfo({});
    }, []);

    const delPointByGroupIdAndTagName = useCallback((tagGroupId, tagName) => {
        setLoading(true)
        get(process.env.REACT_APP_SERVER_PATH + 'api/xinchen/tag/deleteTagGroupMetadata', {
            tagGroupId, tagName
        })
            .then(retData => {
                setLoading(false)
                // console.log(retData)
                if (retData?.data?.error === 0) {
                    message.success('删除点位成功')
                    fetchPointsRef.current(activeGroupId)
                } else {
                    message.warning('删除点位失败')
                }
            })
    }, [activeGroupId])

    const delPointByIndex = useCallback(index => {
        return () => {
            Modal.confirm({
                title: '删除点位',
                icon: <ExclamationCircleOutlined />,
                content: '确认删除该点位吗?',
                okText: '确认',
                centered: true,
                onOk: () => delPointByGroupIdAndTagName(points[index].tagGroupId, points[index].tagName)
            });
        };
    }, [points, delPointByGroupIdAndTagName]);

    const delGroupById = useCallback(id => {
        setLoading(true)
        get(process.env.REACT_APP_SERVER_PATH + 'api/xinchen/tag/deleteTagGroup', {
            id
        })
            .then(retData => {
                setLoading(false)
                // console.log(retData)
                if (retData?.data?.error === 0) {
                    message.success('删除分组成功')
                    fetchGroupsRef.current()
                } else {
                    message.warning('删除分组失败')
                }
            })
    }, [])

    const delGroupByIndex = useCallback(index => {
        return e => {
            e.stopPropagation();
            Modal.confirm({
                title: '删除分组',
                icon: <ExclamationCircleOutlined />,
                content: '确认删除该分组吗?',
                okText: '确认',
                centered: true,
                onOk: () => delGroupById(groups[index].id)
            });
        };
    }, [groups, delGroupById]);

    const modalGroupOnCancel = useCallback(refresh => {
        setCreateGroupInfo(null)
        if (refresh === true) {
            fetchGroupsRef.current()
        }
    }, [])

    const tabOnChange = useCallback(activeKey => {
        const n_activeGroup = groups.filter(({ key }) => key === activeKey)[0] ?? null
        setActiveGroup(n_activeGroup)
    }, [groups])

    const modalPointOnCancel = useCallback(refresh => {
        setAddPointInfo(null)
        if (refresh === true) {
            fetchPointsRef.current(activeGroupId)
        }
    }, [activeGroupId])

    const [sizeConf, setSizeConf] = useState(null)
    const onWrapperResize = useCallback(conf => {
        // console.log(conf)
        const { width, height } = conf
        if (width >= 1840) {
            setSizeConf(conf)
        } else {
            const n_height = 1840 / width * height
            setSizeConf({ width: 1840, height: n_height })
        }
    }, [])

    const [chartInfo, setChartInfo] = useState(null);
    const watchVal = useCallback((item) => {
        return () => {
            setChartInfo(item);
        };
    }, []);

    return (
        <div className={style['out_wrapper']}>
            <div style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }}>
                <DomResize mountedCall={true} onResize={onWrapperResize} />
            </div>
            {
                !!sizeConf && (
                    <ScaleWrapper width={sizeConf.width} height={sizeConf.height} key={`${sizeConf.width}-${sizeConf.height}`}>
                        <div className={style['wrapper']}>
                            <div className='content'>
                                <div className="top">监控看板 / 实时监控</div>
                                <div className="mid_1">
                                    <div className='left'>
                                        <Tabs
                                            activeKey={activeGroup?.key ?? null}
                                            tabBarStyle={{ marginBottom: 0 }}
                                            onChange={tabOnChange}
                                        >
                                            {
                                                (groups ?? [])
                                                    .map((item, index) => {
                                                        const { name, key } = item
                                                        return (
                                                            <TabPane
                                                                tab={(
                                                                    <>{name}<img alt="" src={ICON_CLOSE} className={style['tab_close']} onClick={delGroupByIndex(index)} /></>
                                                                )}
                                                                key={key}
                                                            />
                                                        )
                                                    })
                                            }
                                        </Tabs>
                                    </div>
                                    <div className='right'>
                                        <Button type='primary' shape='round' onClick={createGroup}>创建分组</Button>
                                    </div>
                                </div>
                                <div className="mid_2">
                                    <Button type='primary' shape='round' onClick={addPoint}>+新增监控点位</Button>
                                </div>
                                <div style={{ height: 0 }}>
                                    <DomResize mountedCall={true} onResize={onResize} />
                                </div>
                                <div className="bottom scroll_bar_restyle">
                                    <div className='content' style={{ width: width, gridTemplateColumns: `repeat(${Math.floor(width / 292)},1fr)` }}>
                                        {
                                            (points ?? [])
                                                .map((item, index) => {
                                                    const { name, unit, tagName } = item
                                                    const val = tag2Vals?.[tagName] ?? null
                                                    const p_v = typeof val === 'number' ? significantNum(val) : '--'
                                                    return (
                                                        <div className='item' key={index}>
                                                            <div className='top'>
                                                                <div className='left'>{name}</div>
                                                                <img alt="" src={ICON_CLOSE} className='icon' onClick={delPointByIndex(index)} />
                                                            </div>
                                                            <div className='bottom'>
                                                                <div className='left' onClick={watchVal(item)}>{p_v}</div>
                                                                <div className='right'>{unit}</div>
                                                            </div>
                                                        </div>
                                                    );
                                                })
                                        }
                                    </div>
                                </div>
                            </div>
                            {
                                !!createGroupInfo && (
                                    <ModalGroup
                                        onCancel={modalGroupOnCancel}
                                    />
                                )
                            }
                            {
                                !!addPointInfo && (
                                    <ModalPoint
                                        tagGroupId={activeGroupId}
                                        onCancel={modalPointOnCancel}
                                    />
                                )
                            }
                            {
                                chartInfo && (
                                    <Modal
                                        visible={true}
                                        okButtonProps={{ style: { display: "none" } }}
                                        onCancel={() => {
                                            setChartInfo(null);
                                        }}
                                        width={1200}
                                        title="点位曲线图"
                                        cancelText="关闭"
                                    >
                                        <div style={{ height: 600 }}>
                                            <DiagramChart tag_alias={chartInfo.tagName} />
                                        </div>
                                    </Modal>
                                )
                            }
                            {
                                loading === true && (
                                    <div style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(255,255,255,0.7)' }}>
                                        <Spin />
                                    </div>
                                )
                            }
                        </div>
                    </ScaleWrapper>
                )
            }
        </div>
    );
}

export default Comp;
