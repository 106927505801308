import * as echarts from 'echarts';

const default_color = '#40A1E1';

const renderChart = (refNode, conf, chartData) => {
    if (!!refNode) {
        // render chart
        let instance = echarts.getInstanceByDom(refNode);
        if (!instance) {
            instance = echarts.init(refNode);
        } else {
            instance.clear();
        };

        // console.log(chartData);

        const min = conf?.min ?? undefined;
        const max = conf?.max ?? undefined;
        const color = conf?.color ?? default_color;

        instance.setOption({
            tooltip: {
                trigger: "item",
                formatter: (params) => {
                    // console.log(params);
                    const { marker, data } = params;
                    const [x, y, time] = data;
                    return `${marker}${time}<br/>${conf?.metaHorizon?.name ?? "--"}：${x}<br/>${conf?.metaVertical?.name ?? "--"}：${y}`;
                },
            },
            grid: {
                containLabel: true,
                left: 24,
                top: 24,
                right: 24,
                bottom: 24,
            },
            xAxis: {
                offset: 5,
                scale: true,
                axisLine: {
                    lineStyle: {
                        color: 'rgba(225, 230, 240, 1)',
                    },
                },
                axisLabel: {
                    fontSize: 12,
                    color: 'rgba(104, 125, 163, 1)',
                },
                splitLine: {
                    show: false,
                },
                axisTick: {
                    show: false,
                },
            },
            yAxis: {
                offset: 5,
                scale: true,
                axisLine: {
                    show: false,
                },
                splitLine: {
                    lineStyle: {
                        color: 'rgba(225, 230, 240, 1)',
                        type: "dashed",
                    },
                },
                axisTick: {
                    show: false,
                },
                axisLabel: {
                    fontSize: 12,
                    color: 'rgba(104, 125, 163, 1)',
                },
                min,
                max,
            },
            series: [{
                animation: false,
                symbolSize: 16,
                data: chartData ?? [],
                type: 'scatter',
                itemStyle: {
                    color,
                },
            }]
        });
    };
};

export {
    renderChart,
};