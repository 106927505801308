import style from './index.module.scss'
import { Modal } from 'antd';

const Comp = (props) => {
  const { title, children, ...restProps } = props;
  return (
    <Modal
      {...restProps}
      title={(
        <div className={style['wrapper']}>{title ?? ""}</div>
      )}
    >{children}</Modal>
  )
}

export default Comp;
