import Config from './config.js';
import { get } from './utils.js';

const TIME_EXPIRED = 1000 * 60 * 48;

// 返回的班报数据后，按从早到晚排序好
const loadMyPermissions = (forceUpdate) => new Promise((resolve, reject) => {
    console.log(Config.authApi.API_GET_MY_PERMISSIONS);
    let fetchPermissions = () => get(Config.authApi.API_GET_MY_PERMISSIONS, {
        status: 1
    }).then((retData) => {
        // console.log(retData);
        if (retData.error) {
            reject("network error");
        } else if (retData.data && retData.data.results) {
            window.localStorage.setItem('CACHED_PERMISSION_LIST', JSON.stringify({
                data: retData.data.results,
                time: new Date().getTime(),
            }));
            resolve(retData.data.results);
        } else {
            reject("unknown error");
        }
    })

    if (forceUpdate === true) {
        fetchPermissions();
    } else {
        let preData = window.localStorage.getItem('CACHED_PERMISSION_LIST');
        if (preData == null) {
            fetchPermissions()
        } else {
            preData = JSON.parse(preData);
            let preTime = preData['time'];
            if (new Date().getTime() - preTime < TIME_EXPIRED) {
                resolve(preData['data']);
            } else {
                fetchPermissions();
            }
        }
    }
})

const getMyPermissions = () => {
    let preData = window.localStorage.getItem('CACHED_PERMISSION_LIST');
    if (preData == null) {
        return [];
    } else {
        preData = JSON.parse(preData);
        return preData['data'];
        // let preTime = preData['time'];
        // if(new Date().getTime() - preTime < TIME_EXPIRED){
        //     return preData['data'];
        // }else{
        //     return [];
        // }
    }
}

export {
    loadMyPermissions,
    getMyPermissions
}
