import React, { useEffect, useMemo, useRef, useState } from 'react';
import style from './index.module.scss';
import { useData } from '../hooks';
import * as echarts from 'echarts';
import DomResize from 'components/DomResize';
import { renderChart, get_range } from '../utils';
import moment from 'moment';

const minPeriod = 10;
const maxPeriod = 60;
const periodUnit = 'minutes';

const wait_period = 10000;

const Comp = (props) => {
    const { tag } = props;

    const [timeInfo, setTimeInfo] = useState(() => {
        return Object.assign({}, { range: get_range(minPeriod, periodUnit) });
    });
    useEffect(() => {
        // 每隔一段时长获取数据
        const myInterval = setInterval(() => {
            setTimeInfo((prevTimeInfo) => {
                const latestTimeBeginStr = prevTimeInfo?.range?.[0] ?? null;
                const currTime = moment();
                let nextTimeBegin = moment(currTime).subtract(minPeriod, periodUnit);
                if (typeof latestTimeBeginStr === 'string' && moment(latestTimeBeginStr).isBefore(nextTimeBegin)) {
                    nextTimeBegin = moment(latestTimeBeginStr);
                };
                if (moment(nextTimeBegin).isBefore(moment(currTime).subtract(maxPeriod, periodUnit))) {
                    nextTimeBegin = moment(currTime).subtract(maxPeriod, periodUnit);
                };
                return {
                    range: [nextTimeBegin, currTime].map((time) => time.format("YYYY-MM-DD HH:mm:ss")),
                };
            });
        }, wait_period);
        return () => {
            clearInterval(myInterval);
        };
    }, []);

    const refNode = useRef(null);

    const [data, setData, loading] = useData(null);

    useEffect(() => {
        let conf = null;
        if (!!timeInfo) {
            conf = Object.assign({}, conf ?? {}, { range: timeInfo.range });
        };
        if (typeof tag === 'string') {
            conf = Object.assign({}, conf ?? {}, { tag_name: tag });
        };
        setData(conf);
    }, [timeInfo, tag]);

    useEffect(() => {
        renderChart(refNode.current, data);
    }, [data]);

    const onResize = (conf) => {
        if (!!refNode.current) {
            const instance = echarts.getInstanceByDom(refNode.current);
            if (!!instance) {
                instance.resize();
            };
        };
    };

    const parsed_tag_name = useMemo(() => {
        const name = props?.tagInfo?.name ?? null;
        const alias = props?.tagInfo?.tag_alias ?? null;
        return ((typeof name === 'string' && name !== "") ? `${name} ` : "") + (typeof alias === 'string' && alias !== "" ? alias : "");
    }, [props]);

    return (
        <div className={style['wrapper']}>
            <div className="title">{parsed_tag_name}</div>
            <div className="bottom">
                <div className="resize">
                    <DomResize onResize={onResize} mountedCall={true} />
                </div>
                <div className="chart" ref={refNode}></div>
            </div>
        </div>
    );
};

export default Comp;
