import React, { useRef, useEffect, useState } from 'react';

const debounce = (fn, wait) => {
    let timer = null;
    return () => {
        if (timer !== null) {
            clearTimeout(timer);
        };
        timer = setTimeout(fn, wait);
    };
};

const Comp = (props) => {
    const { onResize, mountedCall } = props; //mountedCall 首次渲染是否通知?

    // 只拿第一次的值
    const debounce_wait = useRef(typeof props.debounce_wait === 'number' ? props.debounce_wait : 0);

    const iframeRef = useRef(null);
    useEffect(() => {
        if (!!iframeRef.current && typeof onResize === 'function') {
            const cb = () => {
                // resized
                const width = iframeRef?.current?.offsetWidth ?? null;
                const height = iframeRef?.current?.offsetHeight ?? null;
                if (typeof width === 'number' && !isNaN(width) && typeof height === 'number' && !isNaN(height)) {
                    onResize({
                        width,
                        height,
                    });
                };
            };
            const parsed_cb = debounce(cb, debounce_wait.current);
            const iframeWindow = iframeRef.current.contentWindow;
            iframeWindow.addEventListener('resize', parsed_cb);
            return () => {
                iframeWindow.removeEventListener('resize', parsed_cb);
            };
        };
    }, [onResize]);

    const [mounted, setMounted] = useState(false);
    useEffect(() => {
        if (!mounted) {
            setMounted(true);
            if (mountedCall === true) {
                if (typeof onResize === 'function' && !!iframeRef.current) {
                    const width = iframeRef?.current?.offsetWidth ?? null;
                    const height = iframeRef?.current?.offsetHeight ?? null;
                    if (typeof width === 'number' && !isNaN(width) && typeof height === 'number' && !isNaN(height)) {
                        onResize({
                            width,
                            height,
                        });
                    };
                };
            };
        };
    }, [mounted, mountedCall, onResize]);

    return (
        <div style={{ width: '100%', height: '100%', opacity: 0, position: 'relative' }}>
            <iframe ref={iframeRef} width="100%" height="100%" title="dom resize"></iframe>
            {/* 下面absolute元素是为了阻止iframe阻断onClick事件 */}
            <div style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }}></div>
        </div>
    );
};

export default Comp;
