import { message } from 'antd'

const checkPwd = (newPassword, againPassword) => {
    // 这里认为用户需要设置密码
    if ((typeof newPassword !== 'string' || newPassword === "") && (typeof againPassword !== 'string' || againPassword === "")) {
        message.warning("请填写密码!");
    } else {
        // 填了内容
        if (typeof newPassword === 'string' && newPassword !== "" && typeof againPassword === 'string' && againPassword !== "") {
            if (newPassword === againPassword) {
                // 先校验6~12位数字字母组合
                // 再判断有无123456
                // if (newPassword.length >= 6 && newPassword.length <= 12) {
                //     if (/^[A-Za-z0-9]+$/.test(newPassword)) {
                //         // 说明只有数字字母
                //         // 验证同时存在数字和字母
                //         if (!!newPassword.match(/[A-Za-z]/) && !!newPassword.match(/[0-9]/)) {
                //             return true;
                //         } else {
                //             message.warning("密码应取6~12位数字和字母组合!");
                //         };
                //     } else {
                //         message.warning("密码应取6~12位数字和字母组合!");
                //     };
                // } else {
                //     message.warning("密码应取6~12位数字和字母组合!");
                // };
                return true;
            } else {
                message.warning("密码不一致, 请确认后重试!");
            };
        } else {
            message.warning("密码不一致, 请确认后重试!");
        };
    };
    return false;
};

function check_password(pwd) {
    var symbol_regex = /[~!@#$%^&*()_+=-{}[\]:"'<>?,./|\\`~]/;
    if (!pwd.match(symbol_regex)) {
        // console.log("!pwd.match(symbol_regex)");
        return false;
    }

    // var normal_regex = /[a-zA-Z0-9]/
    // if (!(pwd.match(/[a-z]/) && pwd.match(/[A-Z]/) && pwd.match(/[0-9]/))) {
    //     // console.log("!pwd.match(normal_regex)");
    //     return false;
    // }

    if (pwd.length < 8) {
        // console.log("pwd.length < 8");
        return false;
    }

    var LxStr = function (str) {
        var arr = str.split('');
        var flag = true;
        for (var i = 1; i < arr.length - 1; i++) {
            var firstIndex = arr[i - 1].charCodeAt();
            var secondIndex = arr[i].charCodeAt();
            var thirdIndex = arr[i + 1].charCodeAt();
            // thirdIndex - secondIndex == 1;
            // secondIndex - firstIndex == 1;
            if ((thirdIndex - secondIndex === 1) && (secondIndex - firstIndex === 1)) {
                flag = false;
            }
        }
        if (!flag) {
            return flag;
        }
        return flag;
    }
    if (!LxStr(pwd)) {
        return false;
    }

    return true;
}


export {
    checkPwd,
    check_password,
};
