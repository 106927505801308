import moment from 'moment';
import { significantNum } from 'common/utils';
import * as echarts from 'echarts';

const get_range = (period, period_unit) => {
    const env_time = process.env.REACT_APP_PROJECT_SYSTEM_DEFAULT_TIME;
    const default_time = (typeof env_time === 'string' && env_time !== "")
        ? (moment(env_time).format("YYYY-MM-DD ") + moment().format("HH:mm:ss"))
        : moment().format("YYYY-MM-DD HH:mm:ss");
    const time_end = moment(default_time);
    const time_begin = moment(time_end).subtract(period, period_unit);
    return [time_begin, time_end].map((time) => time.format("YYYY-MM-DD HH:mm:ss"));
};

const deepColor = "rgba(43,43,43,1)";
const renderChart = (refNode, data) => {
    if (!!refNode) {
        // render chart
        let instance = echarts.getInstanceByDom(refNode);
        if (!instance) {
            instance = echarts.init(refNode);
        };
        const parsed_data_list = data?.parsed_data_list ?? null;
        if (!parsed_data_list) {
            instance.clear();
            return;
        };
        instance.setOption({
            tooltip: {
                trigger: "axis",
                formatter: (params) => {
                    const [{ marker, axisValue, data: [_, value] }] = params;
                    const parsed_value = typeof value === 'number' ? significantNum(value, 4) : "--";
                    return `${moment.unix(axisValue / 1000).format("YYYY-MM-DD HH:mm:ss")}<br />${marker}${parsed_value}`;
                },
            },
            grid: {
                containLabel: true,
                left: 30,
                top: 24,
                right: 30,
                bottom: 40,
            },
            xAxis: {
                type: 'time',
                boundaryGap: false,
                // data: parsed_data_list.map(([time]) => time),
                axisLabel: {
                    color: deepColor,
                    showMinLabel: true,
                    showMaxLabel: true,
                    formatter: (value) => moment.unix(value / 1000).format("HH:mm:ss MM-DD").split(" ").join("\n"),
                },
                axisTick: {
                    lineStyle: {
                        color: deepColor,
                    },
                },
                axisLine: {
                    lineStyle: {
                        color: deepColor,
                    },
                },
                splitLine: {
                    show: true,
                },
            },
            yAxis: {
                type: 'value',
                scale: true,
                axisLabel: {
                    color: deepColor,
                },
                axisTick: {
                    show: true,
                    lineStyle: {
                        color: deepColor,
                    },
                },
                axisLine: {
                    show: true,
                    lineStyle: {
                        color: deepColor,
                    },
                },
            },
            series: [
                {
                    data: parsed_data_list.map(([time, value]) => [moment(time).unix() * 1000, value]),
                    type: 'line',
                    itemStyle: {
                        color: '#FB6060',
                    },
                    symbol: "none",
                },
            ]
        });
    };
};

export {
    get_range,
    renderChart,
};
