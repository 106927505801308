import React from 'react'
import { Form, Checkbox, Table, Button, Input, Spin, message, Modal, Switch, TreeSelect } from 'antd';
import { get, post, patch, reqDelete } from 'common/utils.js';
import md5 from 'md5';
import { getMyPermissions } from 'common/AuthPermission.js';
import { PlusOutlined } from '@ant-design/icons';
import style from './index.module.scss';
import DomResize from 'components/DomResize';

const { Column } = Table;

function check_password(pwd) {
    var symbol_regex = /[~!@#$%^&*()_+=-{}[\]:"'<>?,./|\\`~]/;
    if (!pwd.match(symbol_regex)) {
        // console.log("!pwd.match(symbol_regex)");
        return false;
    }

    // var normal_regex = /[a-zA-Z0-9]/
    // if (!(pwd.match(/[a-z]/) && pwd.match(/[A-Z]/) && pwd.match(/[0-9]/))) {
    //     // console.log("!pwd.match(normal_regex)");
    //     return false;
    // }

    if (pwd.length < 8) {
        // console.log("pwd.length < 8");
        return false;
    }

    var LxStr = function (str) {
        var arr = str.split('');
        var flag = true;
        for (var i = 1; i < arr.length - 1; i++) {
            var firstIndex = arr[i - 1].charCodeAt();
            var secondIndex = arr[i].charCodeAt();
            var thirdIndex = arr[i + 1].charCodeAt();
            // thirdIndex - secondIndex == 1;
            // secondIndex - firstIndex == 1;
            if ((thirdIndex - secondIndex === 1) && (secondIndex - firstIndex === 1)) {
                flag = false;
            }
        }
        if (!flag) {
            return flag;
        }
        return flag;
    }
    if (!LxStr(pwd)) {
        return false;
    }

    return true;
}

export default class Page extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            spinLoading: false,
            userList: [],
            currentUserId: 0,
            current: 1,
            // selectRoleName:[],
            modal_user_name: '',
            modal_mobile: null,
            modal_user_display_name: '',
            modal_role_ids: [],
            roles: [],
            total: 0,
            pageSize: 12,
            userEditorVisible: false,
            modalVisible: false,
            optName: '',
            modifyPasswordVisible: false,
            newPassword: '',
            againPassword: '',
            // role:'',
            myPermissions: getMyPermissions(),

            isShowRowSelectd: this.props.isShowRowSelectd,
            selectedRowKeys: this.props.selectUserRowsIds,

            checkedAuthValues: [],
            roleSelectBlockVisible: false,
            userStatus: 1,
            filterKeyword: '',

            orgs: null,
            org_value: null,

            contentHeight: null,
        }
    }

    componentDidMount() {
        this.getUserList(1)
        this.getRoles();
        this.getOrgs();
    }

    getOrgs = () => {
        get(process.env.REACT_APP_SERVER_PATH + 'api/auth/org/get_orgs_tree', {})
            .then((retData) => {
                // console.log(retData);
                if (!!retData.data && retData.data.errcode === 0) {
                    this.setState({
                        orgs: retData.data.results,
                    });
                } else {
                }
            });
    };

    getRoles = () => {
        let self = this;
        get(process.env.REACT_APP_SERVER_PATH + 'api/auth/roles/list_info', {
            'page': 1,
            'page_size': 999,
        }).then((retData) => {
            // console.log(retData);
            if (retData.data && retData.data.results) {
                self.setState({
                    roles: retData.data.results.roles,
                });
            } else {
            }
        });
    }

    getUserList = (page) => {
        const { pageSize, filterKeyword, checkedAuthValues } = this.state;
        this.setState({ spinLoading: true });
        get(process.env.REACT_APP_SERVER_PATH + 'api/user/users', {
            page: page,
            page_size: pageSize,
            status: "0,1",
            ...(!filterKeyword ? {} : {
                keyword: filterKeyword,
            }),
            ...(checkedAuthValues.length === 0 ? {} : {
                role_ids: checkedAuthValues.join(','),
            })
        }).then((retData) => {
            // console.log(retData);
            this.setState({ spinLoading: false });
            if (retData.data && retData.data.results) {
                this.setState({
                    userList: retData.data.results,
                    current: page,
                    total: retData.data.count
                });
            } else {
            }
        });
    }

    checkPwd = () => {
        const { newPassword, againPassword } = this.state;
        // 这里认为用户需要设置密码
        if ((typeof newPassword !== 'string' || newPassword === "") && (typeof againPassword !== 'string' || againPassword === "")) {
            message.warning("请填写密码!");
        } else {
            // 填了内容
            if (typeof newPassword === 'string' && newPassword !== "" && typeof againPassword === 'string' && againPassword !== "") {
                if (newPassword === againPassword) {
                    // 先校验6~12位数字字母组合
                    // 再判断有无123456
                    // if (newPassword.length >= 6 && newPassword.length <= 12) {
                    //     if (/^[A-Za-z0-9]+$/.test(newPassword)) {
                    //         // 说明只有数字字母
                    //         // 验证同时存在数字和字母
                    //         if (!!newPassword.match(/[A-Za-z]/) && !!newPassword.match(/[0-9]/)) {
                    //             return true;
                    //         } else {
                    //             message.warning("密码应取6~12位数字和字母组合!");
                    //         };
                    //     } else {
                    //         message.warning("密码应取6~12位数字和字母组合!");
                    //     };
                    // } else {
                    //     message.warning("密码应取6~12位数字和字母组合!");
                    // };
                    return true;
                } else {
                    message.warning("密码不一致, 请确认后重试!");
                };
            } else {
                message.warning("密码不一致, 请确认后重试!");
            };
        };
        return false;
    }

    updateUserInfo = () => {
        let self = this;
        // let roleIdList = [];
        const { newPassword, againPassword, userStatus, modal_user_name, modal_role_ids, modal_user_display_name, org_value, modal_mobile } = this.state;
        let org_id = null;
        if (typeof org_value === 'string') {
            const tmp_org_id = parseInt(org_value);
            if (typeof tmp_org_id === 'number') {
                org_id = tmp_org_id;
            };
        };
        if (self.state.modal_user_name === '') {
            message.info("请输入完整信息");
        } else {
            if (parseInt(self.state.currentUserId) === -1) {
                //新建
                // 新建需要设置密码
                const isRight = check_password(newPassword);
                if (!isRight) {
                    message.warning("请按照规则设置新密码");
                    return;
                };
                const checkResult = this.checkPwd();
                if (!checkResult) {
                    return;
                };
                this.setState({ confirmLoading: true });
                post(process.env.REACT_APP_SERVER_PATH + 'api/user/users', {
                    username: modal_user_name,
                    name: modal_user_display_name,
                    md5_pwd: md5(newPassword),
                    status: userStatus,
                    role_ids: modal_role_ids,
                    mobile: modal_mobile,
                    org_id,
                }).then((retData) => {
                    this.setState({ confirmLoading: false });
                    // console.log(retData);
                    if (retData && retData.data) {
                        if (retData.data.errcode === 501) {
                            message.error(retData.data.msg);
                            return;
                        } else if (retData?.data?.errcode === 500) {
                            message.error(retData?.data?.msg);
                            return;
                        };
                        message.success("添加成功");
                        self.getUserList(1);
                        self.setState({
                            userEditorVisible: false
                        });
                    } else {
                        message.error("用户新建失败");
                    }
                });
            } else {
                //修改
                // 修改可以不填密码，如果密码填了再判断一不一致
                if ((typeof newPassword === 'string' && newPassword !== "") || (typeof againPassword === 'string' && againPassword !== "")) {
                    const isRight = check_password(newPassword);
                    if (!isRight) {
                        message.warning("请按照规则设置新密码");
                        return;
                    };
                    const checkResult = this.checkPwd();
                    if (!checkResult) {
                        return;
                    };
                };
                this.setState({ confirmLoading: true });
                patch(process.env.REACT_APP_SERVER_PATH + 'api/user/users/' + self.state.currentUserId, {
                    username: modal_user_name,
                    name: modal_user_display_name,
                    role_ids: modal_role_ids,
                    status: userStatus,
                    mobile: modal_mobile,
                    org_id,
                    ...(!newPassword ? {} : {
                        new_pwd: md5(newPassword),
                    })
                }).then((retData) => {
                    this.setState({ confirmLoading: false });
                    if (retData && retData.data) {
                        if (retData.data.errcode === 0) {
                            message.success("用户修改成功");
                            self.getUserList(self.state.current);
                            self.setState({
                                userEditorVisible: false
                            });
                        } else if (retData.data.errcode === 501) {
                            message.error('该姓名用户已存在');
                        } else {
                            message.error(retData.data.msg);
                        };
                    } else {
                        message.error("用户修改失败");
                    }
                });
            }
        }
    }
    handleDeleteUser = () => {
        const { currentUserId, current } = this.state;
        if (currentUserId === -1) return;
        reqDelete(process.env.REACT_APP_SERVER_PATH + 'api/user/users/' + currentUserId, {})
            .then((retData) => {
                if (retData.data && retData.data.errcode === 0) {
                    message.success("用户删除成功");
                    this.getUserList(current);
                    this.setState({
                        userEditorVisible: false,
                    })
                } else {
                    message.error("用户删除失败");
                }
            });
        this.setState({
            modalVisible: false,
        });
    }
    deleteUser = () => {
        const { currentUserId } = this.state;
        if (currentUserId === -1) {
            return;
        }
        this.setState({
            modalVisible: true
        });
    }
    createPerson = () => {
        this.setState({
            optName: '添加用户',
            userEditorVisible: true,
            // selectRoleName:[],
            modal_user_name: '',
            modal_mobile: null,
            modal_user_display_name: '',
            currentUserId: -1,
            modal_role_ids: [],
            newPassword: '',
            againPassword: '',
            userStatus: 1,
            org_value: null,
        });
    }
    editUser(user) {
        let self = this;
        const { status = 1, org_id } = user;
        let next_org_value = null;
        if (typeof org_id === 'number') {
            next_org_value = org_id + '';
        };
        self.setState({
            optName: '编辑',
            userEditorVisible: true,
            modal_user_name: user.username,
            modal_mobile: user.mobile,
            modal_user_display_name: user.name,
            modal_role_ids: user.roles.map(item => item.id),
            currentUserId: user.id,
            newPassword: '',
            againPassword: '',
            userStatus: status,
            org_value: next_org_value,
        });
    }
    delRole = (id, e) => {
        if (e) e.stopPropagation();
        const { modal_role_ids } = this.state;
        this.setState({
            modal_role_ids: modal_role_ids.filter(item => item !== id),
        })
    }

    roleValueOnClick = id => {
        const { modal_role_ids } = this.state;
        if (modal_role_ids.indexOf(id) > -1) {
            this.delRole(id);
        } else {
            modal_role_ids.push(id);
            this.setState({ modal_role_ids });
        }
    }

    authOnChange = checkedAuthValues => {
        this.setState({ checkedAuthValues }, () => {
            this.getUserList(1);
        });
    }

    orgOnChange = (value, label, extra) => {
        let next_org_value = null;
        if (typeof value === 'string') {
            next_org_value = value;
        };
        this.setState({ org_value: next_org_value });
    }

    onResize = (conf) => {
        // console.log(conf);
        const height = conf?.height;
        this.setState({ contentHeight: height });
    }

    render() {
        let self = this;
        const { roles, checkedAuthValues, currentUserId, roleSelectBlockVisible, newPassword, againPassword, userStatus, orgs, org_value, contentHeight } = this.state;

        return (
            <div className={style['PersonMng']} onClick={() => this.setState({ roleSelectBlockVisible: false })}>
                <div className="content_wrapper">
                    <div className="headerOpt">
                        <Input
                            style={{ width: 180 }}
                            value={this.state.filterKeyword}
                            onChange={(e) => this.setState({ filterKeyword: e.target.value })}
                            placeholder="请输入姓名"
                        />
                        <Button
                            type="primary"
                            className="header-opt-build"
                            style={{ marginLeft: 8 }}
                            onClick={() => {
                                self.getUserList(1);
                            }}
                        >搜索</Button>
                        <Checkbox.Group
                            options={
                                roles
                                    .map(({ id: value, name: label }) => ({ value, label }))
                            }
                            value={checkedAuthValues}
                            onChange={this.authOnChange}
                            style={{ marginLeft: 20 }}
                        />
                        {
                            !this.state.isShowRowSelectd &&
                            <Button style={{ marginLeft: 'auto' }} type="primary" onClick={this.createPerson} className="add_btn"><PlusOutlined />添加</Button>
                        }
                    </div>
                    <div className="table_wrapper">
                        <div className="wrapperRec">
                            <DomResize onResize={this.onResize} mountedCall={true} />
                        </div>
                        {
                            typeof contentHeight === 'number' && (
                                <div className="content">
                                    <Table
                                        rowKey='id'
                                        dataSource={this.state.userList}
                                        pagination={{
                                            defaultCurrent: 1,
                                            total: this.state.total,
                                            pageSize: this.state.pageSize,
                                            showSizeChanger: true,
                                            showQuickJumper: false,
                                            current: this.state.current,
                                        }}
                                        onChange={pagination => {
                                            this.setState({ pageSize: pagination.pageSize }, () => self.getUserList(pagination.current))
                                        }
                                        }
                                        rowClassName={(record, index) => index % 2 === 1 ? 'grey_table_row' : ''}
                                        scroll={{
                                            y: contentHeight - 150,
                                        }}
                                    >
                                        <Column
                                            title={() => (
                                                <span style={{ color: '#357CF7' }}>序号</span>
                                            )}
                                            key='no'
                                            // width={100}
                                            render={(text, record, index) => (index + 1 + "")}
                                        />
                                        <Column
                                            title='姓名'
                                            width={200}
                                            dataIndex='name'
                                        />
                                        <Column
                                            title='用户名'
                                            width={200}
                                            dataIndex='username'
                                        />
                                        <Column
                                            title='手机号'
                                            width={200}
                                            dataIndex='mobile'
                                        />
                                        <Column
                                            title='所属部门'
                                            dataIndex='org_name'
                                        />
                                        {
                                            <Column
                                                title='角色'
                                                dataIndex='roles'
                                                key='roles'
                                                render={(text, record, index) => text.map(item => item.name).join('，')}
                                            />
                                        }
                                        {
                                            !this.state.isShowRowSelectd &&
                                            <Column
                                                title="操作"
                                                key="operate"
                                                width={200}
                                                render={(text, record) => (
                                                    <span onClick={() => this.setState({ currentUserId: record.id })}>
                                                        <Button type="link" size="small" onClick={(e) => this.editUser(record)} style={{ color: '#357CF7' }}>编辑</Button>
                                                    </span>
                                                )}
                                            />
                                        }
                                    </Table>
                                </div>
                            )
                        }
                    </div>
                    {
                        this.state.modalVisible && (
                            <Modal
                                visible={this.state.modalVisible}
                                title="删除"
                                onOk={this.handleDeleteUser}
                                onCancel={() => self.setState({ modalVisible: false })}
                            >
                                <span>确定删除该用户吗？</span>
                            </Modal>
                        )
                    }

                    {
                        this.state.userEditorVisible &&
                        <Modal
                            visible={this.state.userEditorVisible}
                            title={this.state.optName}
                            onOk={this.updateUserInfo}
                            confirmLoading={this.state.confirmLoading}
                            onCancel={() => self.setState({ userEditorVisible: false })}
                            footer={[
                                currentUserId !== -1 ? (
                                    <Button
                                        key="back"
                                        onClick={this.deleteUser}
                                    >
                                        删除用户
                                    </Button>
                                ) : null,
                                <Button key="submit" type="primary" onClick={this.updateUserInfo} style={{ backgroundColor: '#357CF7', borderColor: '#357CF7', }}>
                                    提交
                                </Button>,
                            ]}
                        >
                            <div>
                                <Form className={style["ant-advanced-search-form"]}>
                                    <div className="row_item">
                                        <div className="item_title">姓名</div>
                                        <Input value={this.state.modal_user_display_name} onChange={(e) => this.setState({ modal_user_display_name: e.target.value })} style={{ width: 200 }} />
                                    </div>
                                    <div className="row_item">
                                        <div className="item_title">用户名</div>
                                        <Input value={this.state.modal_user_name} onChange={(e) => this.setState({ modal_user_name: e.target.value })} style={{ width: 200 }} placeholder="姓名拼音" />
                                    </div>
                                    <div className="row_item">
                                        <div className="item_title">手机号</div>
                                        <Input value={this.state.modal_mobile} onChange={(e) => this.setState({ modal_mobile: e.target.value })} style={{ width: 200 }} placeholder="请填写手机号" />
                                    </div>
                                    <div className="row_item">
                                        <div className="item_title">部门</div>
                                        <TreeSelect
                                            allowClear
                                            showSearch
                                            style={{ width: 300 }}
                                            value={org_value}
                                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                            treeData={!!orgs ? orgs : []}
                                            placeholder="请选择部门"
                                            treeDefaultExpandAll
                                            onChange={this.orgOnChange}
                                            treeNodeFilterProp="title"
                                        />
                                    </div>
                                    <div className="row_item">
                                        <div className="item_title">角色</div>
                                        <div className="select_root_wrapper">
                                            <div className="role_select_wrapper" onClick={e => { this.setState({ roleSelectBlockVisible: true }); e.stopPropagation(); }}>
                                                {
                                                    this.state.modal_role_ids.map((id, index) => (
                                                        <div key={index} className="role_item">{
                                                            (this.state.roles.filter(({ id: _id }) => _id === id)[0] || {}).name
                                                        }<span onClick={e => this.delRole(id, e)}>×</span></div>
                                                    ))
                                                }
                                            </div>
                                            {
                                                roleSelectBlockVisible && (
                                                    <div className="role_select_values_wrapper" onClick={e => e.stopPropagation()}>
                                                        <div className="role_select_wrapper">
                                                            {
                                                                this.state.modal_role_ids.map((id, index) => (
                                                                    <div key={index} className="role_item">{
                                                                        (this.state.roles.filter(({ id: _id }) => _id === id)[0] || {}).name
                                                                    }<span onClick={e => this.delRole(id, e)}>×</span></div>
                                                                ))
                                                            }
                                                        </div>
                                                        <div className="select_values_content_wrapper">
                                                            {
                                                                this.state.roles.map(({ id, name }, index) => (
                                                                    <div
                                                                        className={"role_value_item" + (this.state.modal_role_ids.indexOf(id) > -1 ? ' role_value_item_selected' : '')}
                                                                        key={index}
                                                                        onClick={() => this.roleValueOnClick(id)}
                                                                    >{name}</div>
                                                                ))
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                    <div className="row_item">
                                        <div className="item_title">停用该用户</div>
                                        <Switch
                                            checked={userStatus === 0}
                                            onChange={checked => this.setState({ userStatus: checked ? 0 : 1 })}
                                        />
                                    </div>
                                    <div className="secret_hint">长度不低于8位，同时包含数字、字母、大小写及特殊符号 <span className="strong">{"~!@#$%^&*()_+=-{}[]:\"'<>?,./|\\"}</span> 的组合。不包含连续的数字、字母3位以上，如12345，abcde。</div>
                                    <div className="row_item">
                                        <div className="item_title">密码</div>
                                        <Input
                                            value={newPassword}
                                            onChange={e => this.setState({ newPassword: e.target.value })}
                                            style={{ width: 200 }}
                                            placeholder="请输入新密码"
                                        // iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                        />
                                    </div>
                                    <div className="row_item">
                                        <div className="item_title"></div>
                                        <Input
                                            value={againPassword}
                                            onChange={e => this.setState({ againPassword: e.target.value })}
                                            style={{ width: 200 }}
                                            placeholder="请再次输入"
                                        // iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                        />
                                    </div>
                                </Form>
                            </div>
                        </Modal>
                    }
                </div>
                {
                    this.state.spinLoading && (
                        <div className="cus_spin_wrapper">
                            <Spin spinning={true}>
                            </Spin>
                        </div>
                    )
                }
            </div>
        )
    }
}
