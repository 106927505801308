import React from 'react';
import { Typography, Select, Space, Input, InputNumber, Popover, Tabs } from 'antd';
import style from './index.module.scss';
import ChartWrapper from '../Comp/wrapper';
import { SwatchesPicker } from 'react-color';
import { withRouter } from 'react-router';
import { get } from 'common/utils';
import moment from 'moment';

const { Text } = Typography;
const { Option } = Select;
const { TabPane } = Tabs;

const default_color = '#40A1E1';
const defaultYAxis = {
    "upper_limit": null,
    "lower_limit": null,
    "unit": null
};

const range = (
    () => {
        const curr = moment();
        const lastTime = moment(curr).subtract(1, 'weeks');
        return [lastTime, curr];
    }
)();
class Comp extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            title: props?.data?.config?.title ?? null,
            yAxis: props?.data?.config?.y_axis ?? defaultYAxis,
            metaOptionsHorizon: [],
            metaOptionsVertical: [],
            selectedMetaHorizon: props?.data?.config?.metaHorizon ?? null,
            selectedMetaVertical: props?.data?.config?.metaVertical ?? null,
            color: props?.data?.config?.color ?? default_color,
            name: props?.data?.config?.name ?? null,
        };
    }

    componentDidMount() {
        let { registerDataFunc } = this.props;
        if (!!registerDataFunc) {
            registerDataFunc(() => {
                return Promise.resolve(this.getConf());
            });
        };
        this.handleSearch("metaOptionsHorizon")("");
        this.handleSearch("metaOptionsVertical")("");
    }

    handleSearch = (key) => {
        return (value) => {
            get(process.env.REACT_APP_SERVER_PATH + `api/metadata/queryUserMetadata?pageNum=1&pageSize=10&keyWord=${encodeURI(value ?? "")}`, {})
                .then((retData) => {
                    // console.log(retData);
                    if (retData?.data?.error === 0) {
                        const results = (retData?.data?.results?.list ?? [])
                            .map(item => {
                                const { tagName } = item;
                                const n_item = Object.assign(
                                    {},
                                    item,
                                    {
                                        tag_name: tagName
                                    },
                                );
                                return n_item;
                            });
                        this.setState({ [key]: results });
                        return;
                    };
                    this.setState({ [key]: [] });
                });
        };
    };

    selectMeta = (listKey, selectKey) => {
        return (selectedKey) => {
            const { [listKey]: list } = this.state;
            const nextSelected = (list ?? [])
                .filter(({ tag_name }) => tag_name === selectedKey)?.[0] ?? null;
            this.setState({ [selectKey]: nextSelected });
        };
    };

    getConf = () => {
        const { title, yAxis, selectedMetaHorizon, selectedMetaVertical, color, name } = this.state;
        return {
            title,
            y_axis: yAxis,
            metaHorizon: selectedMetaHorizon,
            metaVertical: selectedMetaVertical,
            color,
            name,
        };
    };

    updateYAxis = (key) => {
        return (v) => {
            console.log(v, typeof v);
            if (typeof v === 'number' || v === null) {
                const { yAxis } = this.state;
                const nextYAxis = Object.assign(
                    {},
                    yAxis ?? {},
                    {
                        [key]: v,
                    },
                );
                this.setState({ yAxis: nextYAxis });
            };
        };
    };

    colorOnChange = (color) => {
        this.setState({ color: color?.hex ?? default_color });
    };

    render() {
        let {
            title, yAxis, metaOptionsHorizon, metaOptionsVertical,
            selectedMetaHorizon, selectedMetaVertical,
            color, name,
        } = this.state;

        return (
            <div className={style['wrapper']}>
                <div className="display_block">
                    <ChartWrapper config={this.getConf()} range={range} />
                </div>
                <div className="edit_block">
                    <Tabs defaultActiveKey="1" tabPosition="left">
                        <TabPane tab="基本配置" key="1">
                            <Space direction="vertical">
                                <Space>
                                    <Text>标题：</Text>
                                    <Input placeholder="请输入标题名" value={title} onChange={e => this.setState({ title: e.target.value })} style={{ width: 200 }} />
                                </Space>
                                <Space style={{ marginTop: 10 }}>
                                    <Text>Y轴</Text>
                                    <Text>上限：</Text>
                                    <InputNumber placeholder="请输入上限" value={yAxis.upper_limit} onChange={this.updateYAxis("upper_limit")} style={{ width: 100 }} />
                                    <Text style={{ marginLeft: 10 }}>下限：</Text>
                                    <InputNumber placeholder="请输入下限" value={yAxis.lower_limit} onChange={this.updateYAxis("lower_limit")} style={{ width: 100 }} />
                                </Space>
                            </Space>
                        </TabPane>
                        <TabPane tab="数据项" key="2">
                            <Space>
                                <Space className="leftMetaList" direction="vertical">
                                    <Space className="meta-options-line">
                                        <Text>横轴：</Text>
                                        <Select
                                            showSearch
                                            value={selectedMetaHorizon?.tag_name ?? null}
                                            style={{ width: 250 }}
                                            defaultActiveFirstOption={false}
                                            showArrow={false}
                                            filterOption={false}
                                            onSearch={this.handleSearch("metaOptionsHorizon")}
                                            onChange={this.selectMeta("metaOptionsHorizon", "selectedMetaHorizon")}
                                            notFoundContent={null}
                                        >
                                            {metaOptionsHorizon.map((item, index) => <Option value={item.tag_name} key={index}>{item.name}</Option>)}
                                        </Select>
                                    </Space>
                                    <Space className="meta-options-line">
                                        <Text>竖轴：</Text>
                                        <Select
                                            showSearch
                                            value={selectedMetaVertical?.tag_name ?? null}
                                            style={{ width: 250 }}
                                            defaultActiveFirstOption={false}
                                            showArrow={false}
                                            filterOption={false}
                                            onSearch={this.handleSearch("metaOptionsVertical")}
                                            onChange={this.selectMeta("metaOptionsVertical", "selectedMetaVertical")}
                                            notFoundContent={null}
                                        >
                                            {metaOptionsVertical.map((item, index) => <Option value={item.tag_name} key={index}>{item.name}</Option>)}
                                        </Select>
                                    </Space>
                                </Space>
                                <Space className="rightConf" direction="vertical">
                                    <Space>
                                        <Text>名称：</Text>
                                        <Input
                                            style={{ width: 200 }}
                                            value={name}
                                            onChange={(e) => this.setState({ name: e.target.value })}
                                        />
                                    </Space>
                                    <Space>
                                        <Text>气泡颜色</Text>
                                        <Popover
                                            title="选择颜色"
                                            // trigger="click"
                                            placement="right"
                                            content={(
                                                <Space direction="vertical">
                                                    <SwatchesPicker
                                                        color={color}
                                                        onChangeComplete={this.colorOnChange}
                                                    />
                                                </Space>
                                            )}
                                        >
                                            <div style={{ width: 100, height: 30, backgroundColor: color, borderRadius: 5, cursor: 'pointer' }} />
                                        </Popover>
                                    </Space>
                                </Space>
                            </Space>
                        </TabPane>
                    </Tabs>
                </div>
            </div>
        )
    }
};

export default withRouter(Comp);
