import style from './index.module.scss'
import CusModal from '../CusModal';
import { useCallback, useState } from 'react';
import { Input, message, Spin } from 'antd';
import { post } from 'common/utils';

const blank_func = () => { };

const Comp = (props) => {
  const [loading, setLoading] = useState(false)
  const [value, setValue] = useState('')
  const inputOnchange = useCallback(e => {
    const n_val = e?.target?.value ?? ''
    setValue(n_val)
  }, [])
  const onCancel = useCallback((refresh = false) => {
    const func = props?.onCancel ?? blank_func;
    func(refresh);
  }, [props]);
  const onOk = useCallback(() => {
    // console.log("onOk");
    if (typeof value === 'string' && value !== '') {
      setLoading(true)
      post(process.env.REACT_APP_SERVER_PATH + 'api/xinchen/tag/group', {
        name: value
      })
        .then(retData => {
          // console.log(retData)
          setLoading(false)
          if (retData?.data?.error === 0) {
            // 成功
            message.success('创建成功')
            onCancel(true)
          } else {
            message.warning('创建分组失败')
          }
        })
    } else {
      message.warning('请输入分组名称')
    }
  }, [value, onCancel]);
  return (
    <CusModal
      title="创建分组"
      width={400}
      visible={true}
      onCancel={onCancel}
      onOk={onOk}
      cancelButtonProps={{ shape: "round" }}
      okButtonProps={{ shape: "round" }}
      centered
    >
      <div className={style['wrapper']}>
        <div className='left'>分组名称</div>
        <Input placeholder='请输入分组名称' style={{ width: 0, flexGrow: 1 }} value={value} onChange={inputOnchange} onPressEnter={onOk} autoFocus />
        {
          loading === true && (
            <div style={{ position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(255,255,255,0.7)' }}>
              <Spin />
            </div>
          )
        }
      </div>
    </CusModal>
  )
}

export default Comp;
