// import style from './index.module.scss';

// const blankFunc = () => { };
// const blankFunc_2 = () => blankFunc;

const getColumns = () => {
    return [
        {
            title: "工单编号",
            dataIndex: "id",
            align: "center",
        },
        {
            title: "问题描述",
            dataIndex: "desc",
            align: "center",
        },
        {
            title: "地点",
            dataIndex: "location",
            align: "center",
        },
        {
            title: "类型",
            dataIndex: "typeName",
            align: "center",
        },
        {
            title: "类别",
            dataIndex: "categoryName",
            align: "center",
        },
        {
            title: "维修结果",
            dataIndex: "fixResultName",
            align: "center",
        },
        {
            title: "解决方案",
            dataIndex: "solution",
            align: "center",
        },
        {
            title: "开始时间",
            dataIndex: "beginTime",
            align: "center",
        },
        {
            title: "结束时间",
            dataIndex: "endTime",
            align: "center",
        },
    ];
};

const filterConditions = [
    {
        name: "类型",
        key: "type",
        type: "SELECTOR",
        queryType: 2,
        defaultOptIndex: 0,
        needAll: true,
    },
    {
        name: "类别",
        key: "category",
        type: "SELECTOR",
        queryType: 3,
        defaultOptIndex: 0,
        needAll: true,
    },
    {
        name: "维修结果",
        key: "fixResult",
        type: "SELECTOR",
        queryType: 4,
        defaultOptIndex: 0,
        needAll: true,
    },
    {
        name: "开始时间",
        key: "beginTime",
        type: "TIMERANGE-PICKER",
    },
    {
        name: "结束时间",
        key: "endTime",
        type: "TIMERANGE-PICKER",
    },
]

const mockData = new Array(10)
    .fill(null)
    .map((v, index) => {
        return {
            no: "2021-12-10-09",
            desc: "办公室三楼没有水，尽快处理",
            place: "办公室",
            type: "保修工单",
            category: "电器",
            status: "完成",
            result: "电池没有电，更换电池",
            start_time: "2021-12-06 12:00",
            end_time: "2021-12-07 12:00",
            key: `${index}`,
        };
    });

export {
    getColumns,
    filterConditions,
    mockData,
};
