import React from 'react';
import { withRouter } from 'react-router'
import style from './index.module.scss';
import List from './List';

class Comp extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {

        return (
            <div className={style["wrapper"]}>
                <div className="content">
                    <div className="content">
                        <div className="top">
                            <div className="title">工单管理 / 出库记录</div>
                        </div>
                        <div className="bottom">
                            <List />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default withRouter(Comp);
