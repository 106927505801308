import React from 'react'
import { Divider, Layout, Table, Button, message } from 'antd';
import { post } from 'common/utils.js';
import './RuleList.scss';

const { Column } = Table;

export default class RuleList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            pageSize: 8,
            total: 0,
            dataList: [],
            current: 1,
            deleteAlertPushRuleVisible: false,
            selectRuleId: 0
        }
        this.deleteAlertRule = this.deleteAlertRule.bind(this);
    }
    deleteAlertRule() {
        let self = this;
        //  reqDelete(Config.deviceApi.API_GET_PUSH_RULE_LIST+"/"+self.state.selectRuleId,{
        //  }).then((retData)=>{
        //     if(retData.data&&retData.data.errcode == 0){
        //         message.success("删除成功");
        //         self.getPushRuleList(1);
        //     }else{
        //         message.error("删除失败");
        //     }
        //     self.setState({selectRuleId:0,deleteAlertPushRuleVisible:false});
        // });
    }
    getPushRuleList = (page) => {
        let self = this;
        self.setState({ loading: true });
        post(process.env.REACT_APP_SERVER_PATH + 'api/alarm_push/rules/get_list', {
            page_size: this.state.pageSize,
            page: page,
        }).then((retData) => {
            if (retData.data && retData.data.results) {
                self.setState({
                    dataList: retData.data.results,
                    loading: false,
                    current: page,
                    total: retData.data.count
                });
            } else {
                message.error("加载失败");
            }
        });
    }
    componentWillMount() {
        this.getPushRuleList(1);
    }


    render() {
        let self = this;
        let { pageSize, page, total, dataList, loading } = this.state;
        return (
            <Layout className="AlertPushRuleList layout-container">
                <div className="pg-header">
                    <div className="pg-header-left">
                        <div>
                            <span style={{ color: 'rgba(0,0,0,.45)' }}>告警管理 /</span>
                            <span> 推送规则</span>
                        </div>
                    </div>
                    <div className="pg-header-right"></div>
                </div>
                <Layout className="layout-first">
                    <Layout className="layout-second">
                        <div className="headerOpt">
                            <Button type="primary" className="header-opt-build" onClick={() => self.props.history.push("/System/AlarmPushRuleDetails/-1")} style={{ float: 'right' }} >新建</Button>
                        </div>
                        <Table
                            rowKey='id'
                            dataSource={dataList}
                            pagination={{
                                defaultCurrent: 1,
                                current: page,
                                pageSize: pageSize,
                                total: total,
                                showSizeChanger: false,
                                showQuickJumper: true,
                            }}
                            loading={loading}
                            onChange={pagination => {
                                self.setState({ pageSize: pagination.pageSize }, () => self.getPushRuleList(pagination.current))
                            }
                            }
                        >
                            <Column
                                title='规则名称'
                                dataIndex='name'
                                key='name'
                            />
                            <Column
                                title='告警数量'
                                dataIndex='alarm_num'
                                key='alarm_num'
                            />
                            <Column
                                title='接收人数量'
                                dataIndex='receiver_num'
                                key='receiver_num'
                            />
                            <Column
                                title='创建时间'
                                dataIndex='create_time'
                                key='create_time'
                            />
                            <Column
                                title="操作"
                                key="operate"
                                width={120}
                                render={(text, record) => (
                                    <span>
                                        <a onClick={() => self.props.history.push("/System/AlarmPushRuleDetails/" + record.id)}>编辑</a>
                                        <Divider type="vertical" />
                                        <a onClick={() => self.setState({ deleteAlertPushRuleVisible: true, selectRuleId: record.id })}>刪除</a>
                                    </span>
                                )}
                            />
                        </Table>
                    </Layout>
                </Layout>
            </Layout>
        )
    }
}
