import React from 'react';
import { withRouter } from 'react-router'
import style from './index.module.scss';
import { Spin, DatePicker, Button, Tabs, Radio } from 'antd';
import ChartModules from 'containers/ChartModules';
import moment from 'moment';
import { tabs, getHisDefaultRange } from './utils';

const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

class Comp extends React.Component {

    constructor(props) {
        super(props);
        const selectedTab = tabs[0]
        const range = getHisDefaultRange(selectedTab)
        this.state = {
            loading: false
            , range
            , queryRange: range
            , tabs
            , selectedTab
        }
    }

    rangeOnChange = (v) => {
        let range = v;
        if (!!range) {
            const { selectedTab } = this.state
            range = [moment(moment(range[0]).format(`YYYY-MM-DD ${selectedTab?.type === 'hour' ? 'HH:00:00' : '00:00:00'}`)), moment(moment(range[1]).format(`YYYY-MM-DD ${selectedTab?.type === 'hour' ? 'HH:59:59' : '23:59:59'}`))];
        };
        this.setState({ range });
    };

    tabOnChange = activeKey => {
        const { tabs } = this.state
        const n_selectedTab = tabs.find(({ key }) => key === activeKey) ?? null
        let nRange = null
        if (!!n_selectedTab) {
            nRange = getHisDefaultRange(n_selectedTab)
        }
        this.setState({ selectedTab: n_selectedTab, range: nRange, queryRange: nRange })
    }

    subTabOnChange = e => {
        const val = e?.target?.value ?? null
        const { selectedTab, tabs } = this.state
        let nSelectedTab = null
        const nTabs = tabs
            .map(item => {
                if (item === selectedTab) {
                    const { subTabs } = item
                    const nSubSelectedTab = subTabs.find(({ key }) => key === val) ?? null
                    nSelectedTab = Object.assign(
                        {}
                        , item
                        , {
                            selectedTab: nSubSelectedTab
                        }
                    )
                    return nSelectedTab
                }
                return item
            })
        this.setState({ tabs: nTabs, selectedTab: nSelectedTab })
    }

    watchTrend = () => {
        const { range } = this.state
        let queryRange = null
        if (!!range) {
            queryRange = [].concat(range)
        }
        this.setState({ queryRange })
    }

    render() {
        const { loading, range, tabs, selectedTab, queryRange } = this.state;

        return (
            <div className={style["wrapper"]}>
                <div className="content">
                    {/* 这里后续要考虑item太多左右滚轮 */}
                    <div className="top">
                        <div className="title">监控看板 / 历史趋势</div>
                        <div className='tab-wrapper'>
                            <Tabs
                                activeKey={selectedTab?.key ?? null}
                                onChange={this.tabOnChange}
                                tabBarStyle={{ marginBottom: 0 }}
                            >
                                {
                                    (tabs ?? [])
                                        .map(item => {
                                            const { key, name } = item
                                            return (
                                                <TabPane tab={name} key={key} />
                                            )
                                        })
                                }
                            </Tabs>
                        </div>
                        <div className="right">
                            {
                                !!(selectedTab?.subTabs ?? null) && (
                                    <div className='sub-tab-wrapper'>
                                        <div className='title'>类型</div>
                                        <Radio.Group onChange={this.subTabOnChange} value={selectedTab?.selectedTab?.key ?? null}>
                                            {
                                                selectedTab
                                                    .subTabs
                                                    .map((item, index) => {
                                                        const { key, name } = item
                                                        return (
                                                            <Radio value={key} key={index}>{name}</Radio>
                                                        )
                                                    })
                                            }
                                        </Radio.Group>
                                    </div>
                                )
                            }
                            <div className="title">选择时段</div>
                            <RangePicker
                                style={{ marginLeft: 12 }} value={range} onChange={this.rangeOnChange}
                                {
                                ...selectedTab?.type === "hour" ? {
                                    showTime: true,
                                    format: "YYYY-MM-DD HH时"
                                } : {}
                                }
                            />
                            <Button type='primary' shape='round' style={{ marginLeft: 16 }} onClick={this.watchTrend}>查看趋势</Button>
                        </div>
                    </div>
                    {/* <div style={{ height: 520, margin: "16px 24px" }}>
                        <TableComp />
                    </div> */}
                    {
                        (
                            !!(selectedTab?.selectedTab?.config ?? null) || !!(selectedTab?.config ?? null)
                        ) && (
                            <div className="bottom">
                                <ChartModules
                                    config={selectedTab?.selectedTab?.config ?? (selectedTab?.config)}
                                    range={queryRange}
                                    key={selectedTab?.selectedTab?.key ?? (selectedTab?.key ?? '')}
                                />
                            </div>
                        )
                    }
                </div>
                {
                    loading && (
                        <div style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(255,255,255,0.7)' }}>
                            <Spin />
                        </div>
                    )
                }
            </div>
        );
    }
}

export default withRouter(Comp);
