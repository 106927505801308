import LineChartEdit from 'components/GridModules/LineChart/Edit';
import LineChartWrapper from 'components/GridModules/LineChart/Comp/wrapper';
import LineBarChartEdit from 'components/GridModules/LineBarChart/Edit';
import LineBarChartWrapper from 'components/GridModules/LineBarChart/Comp/wrapper';
import ScatterChartEdit from './ScatterChart/Edit';
import ScatterChartWrapper from './ScatterChart/Comp/wrapper';
import TableChartEdit from './Table/Edit'
import TableChartWrapper from './Table/Comp/wrapper'

const GridModules = [
    {
        key: "BOARD_TEST_01",
        editComp: LineChartEdit,
        comp: LineChartWrapper,
        isDev: true,
        name: '折线图',
    },
    {
        key: "LINEBAR_CHART_V1",
        editComp: LineBarChartEdit,
        comp: LineBarChartWrapper,
        name: '柱状图+折线图',
    },
    {
        key: "SCATTER_CHART_V1",
        editComp: ScatterChartEdit,
        comp: ScatterChartWrapper,
        name: '散点(气泡)图',
    },
    {
        key: "TABLE_CHART_V1",
        editComp: TableChartEdit,
        comp: TableChartWrapper,
        name: '表格图',
    },
];

export {
    GridModules,
};