import React from 'react';
import style from './index.module.scss';
import DomResize from 'components/DomResize';
import { Table } from 'antd';
import { getColumns } from './utils';

export default class Comp extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            columns: getColumns(props?.config ?? null),
            height: 0,
        };
    }

    onResize = (conf) => {
        let n_height = (conf?.height ?? 0) - 70;
        n_height = n_height > 0 ? n_height : 0;
        this.setState({ height: n_height });
    }

    render() {
        const { columns, height } = this.state;
        const {
            config
            , data
            , loading
        } = this.props

        return (
            <div className={style["wrapper"]}>
                <div className="header">
                    <div className="colored_bar"></div>
                    <div className="title">{config?.title ?? "--"}</div>
                </div>
                <div className="content">
                    <div className="resize_wrapper">
                        <DomResize onResize={this.onResize} mountedCall={true} />
                    </div>
                    <div className='table_wrapper'>
                        <Table
                            loading={!!loading}
                            columns={columns ?? []}
                            dataSource={data ?? []}
                            pagination={false}
                            size='small'
                            scroll={{
                                y: height,
                            }}
                        />
                    </div>
                </div>
            </div>
        );
    }
}
