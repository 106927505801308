import React, { useCallback, useState } from 'react';
import { Responsive } from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import style from './index.module.scss';
import ItemWrapper from './ItemWrapper.js';
import { withRouter } from 'react-router';
import DomResize from 'components/DomResize';

const parseLayout = (layout, minWidth, minHeight) => {
    return layout
        .map((item) => {
            const { h, w } = item;
            return {
                ...item,
                minH: minHeight,
                minW: minWidth,
                static: true,
                h: (h > minHeight ? h : minHeight),
                w: (w > minWidth ? w : minWidth),
            };
        });
};

//BOARD_TEST_01
const Page = (props) => {
    const { config } = props
    // layout 配置相关
    const [layout] = useState(() => {
        return parseLayout(config.layout, config.grid_config.min_width, config.grid_config.min_height)
    })

    const [contentWidth, setContentWidth] = useState(null);
    const onResize = useCallback((conf) => {
        setContentWidth(conf?.width ?? null);
    }, []);
    return (
        <div className={style['wrapper']}>
            {
                !!layout && (
                    <div className="grid_wrapper scroll_bar_restyle">
                        <div style={{ height: 0 }}>
                            <DomResize mountedCall={true} onResize={onResize} />
                        </div>
                        {
                            typeof contentWidth === 'number' && (
                                <Responsive
                                    rowHeight={config.grid_config.row_height}
                                    cols={config.grid_config.cols}
                                    breakpoints={config.grid_config.breakpoints}
                                    verticalCompact={false}
                                    width={contentWidth}
                                >
                                    {
                                        layout
                                            .map(item => {
                                                const { i, x, y, w, h, static: staticKey = false, minH, minW } = item;
                                                // console.log(item);
                                                return (
                                                    <div key={i} data-grid={{ x, y, w, h, static: staticKey, minH, minW }} style={{ borderRadius: 4, boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.05)", position: 'relative', overflow: 'hidden' }}>
                                                        <ItemWrapper id={parseInt(i)} range={props?.range ?? null} />
                                                    </div>
                                                );
                                            })
                                    }
                                </Responsive>
                            )
                        }
                    </div>
                )
            }
        </div>
    );
};

export default withRouter(Page);
