// import style from './index.module.scss';

// const blankFunc = () => { };
// const blankFunc_2 = () => blankFunc;

const getColumns = () => {
    return [
        {
            title: "备件序号",
            dataIndex: "devSeq",
            align: "center",
        },
        {
            title: "备件名称",
            dataIndex: "devName",
            align: "center",
        },
        {
            title: "规格/型号",
            dataIndex: "model",
            align: "center",
        },
        {
            title: "单位",
            dataIndex: "unit",
            align: "center",
        },
        {
            title: "出库数量",
            dataIndex: "quantity",
            align: "center",
        },
        {
            title: "出库项目",
            dataIndex: "itemName",
            align: "center",
        },
        {
            title: "出库日期",
            dataIndex: "createTime",
            align: "center",
        },
        {
            title: "出库人",
            dataIndex: "userName",
            align: "center",
        },
        {
            title: "制造商/品牌",
            dataIndex: "brand",
            align: "center",
        },
    ];
};

const filterConditions = [
    {
        name: "出库项目",
        key: "item",
        type: "SELECTOR",
        queryType: 1,
        defaultOptIndex: 0,
        needAll: true,
    },
    {
        name: "出库时间",
        key: "output_time",
        type: "TIMERANGE-PICKER",
    },
]

const mockData = new Array(10)
    .fill(null)
    .map((v, index) => {
        return {
            no: "468",
            desc: "LED灯",
            place: "8W",
            type: "个",
            category: "9",
            status: "一车间精测间",
            result: "2021-12-06",
            start_time: "刘长明",
            end_time: "飞利浦",
            key: `${index}`,
        };
    });

export {
    getColumns,
    filterConditions,
    mockData,
};
